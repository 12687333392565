import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EventRecord } from "./queryEventsSlice";

export enum InventoryActionEnum {
	Select = "Select",
	Edit = "Edit",
}
export enum ManageInventoryTabEnum {
	Autopricer = "Autopricer",
	ManageListing = "ManageListing",
}
interface ISelectedEvent {
	event: EventRecord | null;
	showComingSoon: boolean;
	inventoryHasChanges: boolean;
	inventoryAction: InventoryActionEnum;
	manageInventoryTab: ManageInventoryTabEnum;
	inventoryItemIds: Array<number>;
}

const initialState: ISelectedEvent = {
	event: null,
	showComingSoon: false,
	inventoryHasChanges: false,
	inventoryAction: InventoryActionEnum.Select,
	manageInventoryTab: ManageInventoryTabEnum.ManageListing,
	inventoryItemIds: [],
};

export const selectionSlice = createSlice({
	name: "selectionSlice",
	initialState,
	reducers: {
		setInventoryHasChanges: (state, action: PayloadAction<{ inventoryHasChanges: boolean }>) => {
			return {
				...state,
				...action.payload,
			};
		},
		setEvent: (state, action: PayloadAction<{ event: EventRecord | null }>) => {
			return {
				...state,
				...action.payload,
			};
		},
		setInventory: (
			state,
			action: PayloadAction<{ inventoryItemIds?: Array<number>; inventoryAction: InventoryActionEnum }>,
		) => {
			return {
				...state,
				...action.payload,
			};
		},
		setManageInventoryTab: (state, action: PayloadAction<ManageInventoryTabEnum>) => {
			state.manageInventoryTab = action.payload;
		},
		reset: (state) => {
			state.event = null;
			state.inventoryItemIds = [];
		},
		showComingSoon: (state, action: PayloadAction<{ showComingSoon: boolean }>) => {
			return {
				...state,
				...action.payload,
			};
		},
	},
});
