import React from "react";
import styles from "./SpacedLabelValue.module.scss";

type TSpacedLabelValue = {
	label: string;
	value?: number | string | null;
};

const SpacedLabelValue: React.FC<TSpacedLabelValue> = ({ label, value }) => {
	return (
		<div className={styles.root}>
			<div className={styles.label}>{label}</div>
			<div className={styles.value}>{value ?? "/"}</div>
		</div>
	);
};

export default SpacedLabelValue;
