import { ArrowDownIcon, ArrowUpIcon } from "@src/assets/general-icons";
import React from "react";
import { Button, ButtonVariant } from "./Button";

interface ITableExpandButton {
	isExpanded?: boolean;
	onClick?: () => void;
}

export const TableExpandButton: React.FC<ITableExpandButton> = ({ isExpanded, onClick }) => {
	return (
		<Button
			onClick={onClick}
			style={{
				minWidth: "48px",
				minHeight: "14px",
				display: "grid",
				placeItems: "center",
				padding: "0px",
				background: "#ec6728",
			}}
			variant={ButtonVariant.Tertiary}>
			{isExpanded ? <ArrowUpIcon color="white" /> : <ArrowDownIcon color="white" />}
		</Button>
	);
};
