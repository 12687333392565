import { ExclamationIcon } from "@src/assets/general-icons";
import { MyTooltip } from "@src/components/tooltip/Tooltip";
import React from "react";
import manageListingStyles from "../../../ManageListing.module.scss";
import styles from "../../AdvancedTab.module.scss";

interface ISubHeader extends React.HTMLAttributes<HTMLDivElement> {
	title: string;
	tooltipText?: React.ReactNode;
	rightContent?: React.ReactNode;
	isHighlighted?: boolean;
}

export const SubHeader: React.FC<ISubHeader> = ({ title, tooltipText, rightContent, isHighlighted, ...rest }) => {
	const id = title.replace(" ", "-") + "tooltip-id";

	const content = tooltipText ? (
		<MyTooltip
			popup={<div style={{ fontSize: "12px" }}>{tooltipText}</div>}
			id={id}
			element={
				<div>
					<ExclamationIcon />
				</div>
			}
		/>
	) : null;

	return (
		<div className={styles.subHeaderContainer} {...rest}>
			<div className={styles.left}>
				<div data-is-highlighted={isHighlighted} style={{ padding: "0px" }} className={manageListingStyles.subHeader}>
					{title}
				</div>
				{content}
			</div>
			{rightContent ? <div className={styles.right}>{rightContent}</div> : null}
		</div>
	);
};
