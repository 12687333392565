import {
	DashboardIcon,
	IIconActiveProps,
	NotificationIcon,
	PricingIcon,
	PurchasingIcon,
	ReportsIcon,
	SalesIcon,
	SettingsIcon,
	ThemeIcon,
} from "@src/assets/sidebar-Icons";
import { paths } from "@src/routes/paths";
import { settingsActions } from "@src/store";
import { Theme } from "@src/store/settingsSlice";
import { store } from "@src/store/store";
import { getDatePickerFormattedDate } from "../utils/dateUtils";

export interface ISubItem {
	id: number;
	label: string;
	onClick?: () => void;
	type?: "navigation";
	navigationPath?: (typeof paths)[keyof typeof paths];
}

export interface IItem {
	id: number;
	label: string;
	icon: React.FC<IIconActiveProps>;
	navigateTo?: string;
	subItems?: Array<ISubItem>;
	isDisabled: boolean;
}

const changeTheme = (theme: Theme) => {
	store.dispatch(settingsActions.changeTheme(theme));
};

const isQA = store.getState().settings.isQA;

const getInitialSalesQuery = () => {
	const today = new Date();
	const oneMonthAgo = new Date();
	oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 2);

	const eventFromDate = getDatePickerFormattedDate(oneMonthAgo);
	const eventToDate = getDatePickerFormattedDate(today);
	const saleFromDate = getDatePickerFormattedDate(oneMonthAgo);
	const saleToDate = getDatePickerFormattedDate(today);

	return `?eventFromDate=${eventFromDate}&eventToDate=${eventToDate}&saleFromDate=${saleFromDate}&saleToDate=${saleToDate}`;
};

export const mainItems: Array<IItem> = [
	{
		id: 1,
		label: "dashboard",
		icon: DashboardIcon,
		navigateTo: paths.dashboard,
		isDisabled: true,
	},
	{
		id: 2,
		label: "inventory",
		icon: PricingIcon,
		navigateTo: paths.pricing,
		isDisabled: false,
	},
	{
		id: 3,
		label: "reports",
		icon: ReportsIcon,
		navigateTo: paths.reports,
		isDisabled: true,
	},
	{
		id: 4,
		label: "sales",
		icon: SalesIcon,
		navigateTo: paths.sales + getInitialSalesQuery(),
		subItems: [
			{ id: 1, label: "Invoices", type: "navigation", navigationPath: paths.sales },
			{ id: 2, label: "Invoices Paid", type: "navigation", navigationPath: paths.sales },
			{ id: 3, label: "Payment", type: "navigation", navigationPath: paths.sales },
			{ id: 4, label: "Payment Advanced", type: "navigation", navigationPath: paths.sales },
			{ id: 5, label: "Graphs", type: "navigation", navigationPath: paths.sales },
			{ id: 6, label: "Fullfillment", type: "navigation", navigationPath: paths.sales },
		],
		isDisabled: !isQA,
	},
	{
		id: 5,
		label: "purchasing",
		icon: PurchasingIcon,
		navigateTo: paths.purchasing,
		isDisabled: true,
	},
];

export const settings: Array<IItem> = [
	{
		id: 1,
		label: "themes",
		icon: ThemeIcon,
		subItems: [
			{ id: 1, label: "Light", onClick: () => changeTheme(Theme.Light) },
			// Disabled Dark theme for now, if you wan't to enable it again
			// just change to changeTheme(Theme.Dark)
			{ id: 2, label: "Dark", onClick: () => changeTheme(Theme.Light) },
		],
		isDisabled: false,
	},
	{
		id: 2,
		label: "notification",
		icon: NotificationIcon,
		navigateTo: paths.notification,
		isDisabled: true,
	},
	{
		id: 3,
		label: "settings",
		icon: SettingsIcon,
		navigateTo: paths.settings,
		isDisabled: true,
	},
];
