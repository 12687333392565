import { login } from "./account/loginSlice";
import { validateToken } from "./account/validateTokenSlice";
import { settingsSlice } from "./settingsSlice";

import { getMandatoryNotifications } from "./notifications/getMandatoryNotificationsSlice";
import { getMappingCounts } from "./notifications/getMappingCountsSlice";

import { listCategories } from "./pricing/listCategoriesSlice";
import { listCompanies } from "./pricing/listCompaniesSlice";
import { listHeadliners } from "./pricing/listHeadlinersSlice";
import { listVenues } from "./pricing/listVenuesSlice";
import { queryInventory, queryInventorySlice } from "./pricing/queryInventorySlice";

import { listCustomers } from "./sales/listCustomersSlice";
import { querySales } from "./sales/querySalesSlice";

import mapSlice from "./map/mapSlice";
import { getActiveListings } from "./marketplace/seatgeek/getActiveListingsSlice";
import { getEventMap, getEventMapSlice } from "./marketplace/seatgeek/getEventMapSlice";
import { getLowestComparables } from "./marketplace/seatgeek/getLowestComparables";
import { getSoldListings } from "./marketplace/seatgeek/getSoldListingsSlice";
import { getPOSSettings } from "./pos/getPOSSettingsSlice";
import { appendListingNotes } from "./pricing/appendListingNotes";
import { disableFlashSale } from "./pricing/disableFlashSale";
import { getListingSharedSettings, getSharedSettings } from "./pricing/getSharedSettings";
import { holdTickets } from "./pricing/holdTicketsSlice";
import { updateSharing } from "./pricing/listingsSharing";
import { queryEventInventoryAll, queryEventInventoryAllSlice } from "./pricing/queryEventInventoryAllSlice";
import { queryEvents, queryEventsSlice } from "./pricing/queryEventsSlice";
import { releaseListings } from "./pricing/releaseListings";
import { deleteRule, getSavedRules, saveRule } from "./pricing/savedRulesSlice";
import { selectionSlice } from "./pricing/selectionSlice";
import { settingsSlice as pricingSettingsSlice, setEventColumns, setInventoryColumns } from "./pricing/settingsSlice";
import { updateSplitListing } from "./pricing/splitListings";
import { updateSplitListingAdvanced } from "./pricing/splitListingsAdvanced";
import { updateFlashSale } from "./pricing/updateFlashSale";
import { updateListingCosts } from "./pricing/updateListingCosts";
import { updateListingPosition } from "./pricing/updateListingPosition";
import { updateListingPrices } from "./pricing/updateListingPrices";
import { updateSchedulePrice } from "./pricing/updateSchedulePrice";
import { getListingsItems, getListingsItemsSlice, listingsItemsSlice } from "./purchasing/getListingsItemsSlice";
import { updatePurchaseInfo } from "./purchasing/updatePurchaseInfo";
import { createExternalInvoice } from "./sales/createExternalInvoice";
import { listExchanges } from "./sales/listExchanges";

import { listStates } from "./system/ListStatesSlice";
import { viewItemHistory } from "./system/ViewHistory";
import { addEventTags } from "./system/addEventTagsSlice";
import { addItemTags } from "./system/addItemsTagsSlice";
import { attachPdfs } from "./system/attachPdfs";
import { deletePdfs } from "./system/deletePdfs";
import { editTickets } from "./system/editTickets";
import { forceAttachPdfs } from "./system/forceAttachPdfs";
import { getEventTags } from "./system/getEventTags";
import { getFile } from "./system/getFile";
import { getSeats, getSeatsSlice } from "./system/getGetSeats";
import { getItemPng } from "./system/getItemPng";
import { getListingTags } from "./system/getListingTags";
import { listAllTags } from "./system/listAllTagsSlice";
import { listCommonHelp } from "./system/listCommonHelpSlice";
import { listExportDestinations } from "./system/listExportDestinationsSlice";
import { listLocations } from "./system/listLocationsSlice";
import { removeEventTag } from "./system/removeEventTag";
import autopricerSlice, { saveRules, setListingRules } from "@src/store/auto-pricer/autopricerSlice";
import { removeTag } from "./system/removeTag";
import { splitPDFs } from "./system/splitPDFs";
import { supportAttachmentRequest } from "./system/supportAttachmentRequest";
import { updateCostPerTicket } from "./system/updateCostPerTicket";
import { getListingRulesSlice, getQuickActionListingRules } from "./auto-pricer/getListingRulesSlice";
import { removeAutopricer } from "./auto-pricer/removeAutopricerSlice";
import { getSeasonInventory } from "./auto-pricer/getSeasonInventory";

export const accountActions = {
	login,
	validateToken,
};

export const marketplaceActions = {
	seatgeek: {
		getEventMap,
		...getEventMapSlice.actions,
		getLowestComparables,
		getActiveListings,
		getSoldListings,
	},
};

export const notificacionsActions = {
	getMandatoryNotifications,
	getMappingCounts,
};

export const posActions = {
	getPOSSettings,
};

export const pricingActions = {
	listCategories,
	listCompanies,
	listHeadliners,
	listVenues,
	updateListingPrices,
	updateListingPosition,
	updateListingCosts,
	updateFlashSale,
	updateSplitListing,
	updateSplitListingAdvanced,
	disableFlashSale,
	updateSchedulePrice,
	...getSharedSettings.actions,
	queryEventInventoryAll,
	getSavedRules,
	saveRule,
	deleteRule,
	updateSharing,
	getListingSharedSettings,
	...queryEventInventoryAllSlice.actions,
	queryEvents,
	...queryEventsSlice.actions,
	queryInventory,
	...queryInventorySlice.actions,
	...pricingSettingsSlice.actions,
	setEventColumns,
	setInventoryColumns,
	holdTickets,
	appendListingNotes,
	releaseListings,
};

export const eventActions = {
	...selectionSlice.actions,
};

export const mapActions = {
	...mapSlice.actions,
};

export const salesActions = {
	listCustomers,
	createExternalInvoice,
	listExchanges,
	querySales,
};

export const purchasingActions = {
	getListingsItems,
	updatePurchaseInfo,
	...getListingsItemsSlice.actions,
	...listingsItemsSlice.actions,
};
export const systemActions = {
	listAllTags,
	listCommonHelp,
	listExportDestinations,
	listLocations,
	listStates,
	viewItemHistory,
	addItemTags,
	addEventTags,
	editTickets,
	getListingTags,
	getEventTags,
	removeEventTag,
	removeTag,
	splitPDFs,
	attachPdfs,
	getFile,
	getSeats,
	...getSeatsSlice.actions,
	getItemPng,
	deletePdfs,
	forceAttachPdfs,
	supportAttachmentRequest,
	updateCostPerTicket,
};

export const settingsActions = {
	...settingsSlice.actions,
};

export const autopricerActions = {
	...autopricerSlice.actions,
	setListingRules,
	saveRules,
	...getListingRulesSlice.actions,
	getQuickActionListingRules,
	removeAutopricer,
	...getListingRulesSlice.actions,
	...getSeasonInventory.actions,
};
