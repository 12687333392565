// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SpacedLabelValue-module_root___PKKoz {
  display: flex;
  font-size: 13px;
}
.SpacedLabelValue-module_root___PKKoz .SpacedLabelValue-module_label___hR0hY {
  min-width: 120px;
  font-weight: 700;
}
.SpacedLabelValue-module_root___PKKoz .SpacedLabelValue-module_value___gPpBI {
  word-break: break-all;
}`, "",{"version":3,"sources":["webpack://./src/components/helpAndSupport/SpacedLabelValue.module.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,eAAA;AACD;AACC;EACC,gBAAA;EACA,gBAAA;AACF;AAEC;EACC,qBAAA;AAAF","sourcesContent":[".root {\r\n\tdisplay: flex;\r\n\tfont-size: 13px;\r\n\r\n\t.label {\r\n\t\tmin-width: 120px;\r\n\t\tfont-weight: 700;\r\n\t}\r\n\r\n\t.value {\r\n\t\tword-break: break-all;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `SpacedLabelValue-module_root___PKKoz`,
	"label": `SpacedLabelValue-module_label___hR0hY`,
	"value": `SpacedLabelValue-module_value___gPpBI`
};
export default ___CSS_LOADER_EXPORT___;
