import React, { forwardRef, useImperativeHandle, useRef, useState, useLayoutEffect } from "react";
import styles from "./ContextMenu.module.scss";
import useClickOutside from "@src/hooks/useClickOutside";
import { SalesData } from "@src/pages/home/children/sales/SalesTable/SalesTable.constants";

export type TContextState = {
	isOpen: boolean;
	x: number;
	y: number;

	selectedItem?: SalesData | null;
};

export type ContextMenuHandle = {
	updateContextMenu: (data: Partial<TContextState>) => void;
	contextData: TContextState;
};

type TContextMenuProps = {
	children: JSX.Element | ((selectedItem: SalesData | null) => JSX.Element);
};

const ContextMenu = forwardRef<ContextMenuHandle, TContextMenuProps>(({ children }, ref) => {
	const menuRef = useRef<HTMLDivElement>(null);
	const [state, setState] = useState<TContextState>({
		isOpen: false,
		x: 0,
		y: 0,
	});

	useClickOutside(menuRef, () => {
		setState((prev) => ({ ...prev, isOpen: false }));
	});

	useLayoutEffect(() => {
		if (state.isOpen && menuRef.current) {
			const margin = 10;
			const { height, width } = menuRef.current.getBoundingClientRect();
			const { innerHeight, innerWidth } = window;

			let x = state.x;
			let y = state.y;

			if (x + width + margin > innerWidth) {
				x = innerWidth - width - margin;
			}

			if (y + height + margin > innerHeight) {
				y = innerHeight - height - margin;
			}

			setState((prev) => ({ ...prev, x, y }));
		}
	}, [state.isOpen, state.x, state.y]);

	useImperativeHandle(ref, () => ({
		updateContextMenu: (data: Partial<TContextState>) => {
			setState((prev) => ({ ...prev, ...data }));
		},
		contextData: state,
	}));

	if (!state.isOpen) return null;

	return (
		<div
			ref={menuRef}
			className={styles.contextRoot}
			style={{
				left: `${state.x}px`,
				top: `${state.y}px`,
			}}>
			{typeof children === "function" ? children(state.selectedItem ?? null) : children}
		</div>
	);
});

export default ContextMenu;
