import {
	Axs,
	FanXchange,
	GameTime,
	SeatGeek,
	StubHub,
	StubHubInt,
	TicketEvolution,
	TicketNetwork,
	TicketsNow,
	TickPick,
	ViaGogo,
	VividSeats,
} from "@src/assets/martkeplace-icons";
import { extractMarketplaceData } from "@src/store/pricing/settingsSlice";
import React from "react";
import { MyTooltip } from "../tooltip/Tooltip";

export const allMarketplaces = [
	{ icon: <StubHub />, name: "StubHub", id: 104, key: "StubHub_Listing_ID", isDisabled: false },
	{ icon: <StubHubInt />, name: "StubHub International", id: 126, key: "StubHubInt_Listing_ID", isDisabled: false },
	{ icon: <TicketsNow />, name: "Tickets Now", id: 106, key: "TicketsNow_Listing_ID", isDisabled: false },
	{ icon: <VividSeats />, name: "Vivid Seats", id: 115, key: "Vivid_Listing_ID", isDisabled: false },
	{ icon: <TicketNetwork />, name: "Ticket Network", id: 105, key: "TicketNetwork_Listing_ID", isDisabled: false },
	{ icon: <SeatGeek />, name: "Seat Geek", id: 117, key: "SeatGeek_Listing_ID", isDisabled: false },
	{ icon: <Axs />, name: "AXS", id: 125, key: "AXS_Listing_ID", isDisabled: true },
	{ icon: <GameTime />, name: "Game Time", id: 121, key: "", isDisabled: false },
	{ icon: <TicketEvolution />, name: "Ticket Evolution", id: 116, key: "", isDisabled: false },
	{ icon: <TickPick />, name: "TIck Pick", id: 118, key: "", isDisabled: false },
	{ icon: <FanXchange />, name: "Fan X Change", id: 122, key: "", isDisabled: false },
	{ icon: <ViaGogo />, name: "ViaGogo", id: 120, key: "", isDisabled: false },
];

export const populateMarketplaceIcons = (data: ReturnType<typeof extractMarketplaceData>) => {
	const activeIds = Object.entries(data)
		.map(([key, value]) => value && key)
		.filter((x) => x);

	return activeIds.map((x) => {
		const i = allMarketplaces.find((i) => i.key === x);
		if (!i) return null;
		const tooltipId = `mp-icons${data.Listing_ID}-${i.name.replace(" ", "-")}`;
		return (
			<React.Fragment key={i.id}>
				<MyTooltip id={tooltipId} popup={i.name} element={<span>{i.icon}</span>} />
			</React.Fragment>
		);
	});
};
