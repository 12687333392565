import ReactGA from "react-ga4";

const isQA = window.location.origin.includes("qa") || window.location.origin.includes("localhost");
const disableGoogleAnalytics = isQA;
const googleAnalyticsToken = process.env.GOOGLE_ANALYTICS_ID;

if (googleAnalyticsToken && !disableGoogleAnalytics) {
	ReactGA.initialize(googleAnalyticsToken);
} else {
	console.log("%cGoogle Analytics, not available", "background-color:#44e088;color:black;border-radius:8px;padding: 2px 10px");
}
