import { CalendarIcon } from "@src/assets/general-icons";
import React, { CSSProperties, useState } from "react";
import DatePicker from "react-datepicker";
import { Control, FieldValues, Path, useController } from "react-hook-form";
import { clx } from "../utils/stringUtils";
import styles from "./DateInput.module.scss";
import "./DateStyles.css";
import inputStyles from "./Input.module.scss";

type TDateOrNull = Date | null;
type TDateOrUndefind = Date | undefined;

interface IDateInput<T extends FieldValues> {
	control: Control<T>;
	name: Path<T>;
	controlSize?: "n" | "sm";
	wrapperStyle?: CSSProperties;
	placeholder?: string;
}

export const DateInput = <T extends FieldValues>({
	controlSize = "n",
	wrapperStyle,
	name,
	control,
	placeholder = "Default placeholder",
	...rest
}: IDateInput<T>) => {
	const { field, fieldState } = useController({ name, control });
	const [startDate] = useState<TDateOrUndefind>(new Date());

	const onDateChange = (data: TDateOrNull) => {
		let formattedDate: string | undefined;
		if (!data) formattedDate = "";
		else formattedDate = `${data?.getMonth() + 1}-${data?.getDate()}-${data?.getFullYear()}`;
		field.onChange(formattedDate);
	};

	const selected = field.value ? new Date(field.value) : null;
	const { isDirty, invalid } = fieldState;

	const innerTernary = field.value && isDirty ? "success" : "none";
	const inputStatus = invalid ? "error" : innerTernary;

	return (
		<div className={clx({ [styles.root]: true, [inputStyles.small]: controlSize == "sm" })} style={wrapperStyle} {...rest}>
			<DatePicker
				className={clx({
					[styles.input]: true,
					[styles.inputError]: inputStatus === "error",
					[inputStyles.small]: controlSize == "sm",
				})}
				calendarClassName={styles.test}
				placeholderText={placeholder}
				selected={selected}
				startDate={startDate}
				minDate={startDate}
				onChange={onDateChange}
				dateFormat="MM/dd/yyyy"
				todayButton="Today"
				// locale="en-US" //experimental
				// showWeekNumbers //experimental
			/>
			<div className={styles.iconContainer}>
				<CalendarIcon />
			</div>
		</div>
	);
};
