import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ApiProvider } from "../ApiProvider";
import { getInitialState } from "../helpers";
import { toast } from "sonner";

type TSeasonsPayload = {
	listingId: number;
};

interface ISeasonItem {
	Event_Headliner_ID: number;
	Event_Headliner: string;
	Cat_Type: string;
	SeatGeek_Event_ID: number;
	Event_ID: number;
	Event_Name: string;
	Event_Date: string;
	Event_Time: string;
	Venue_Name: string;
	Section: string;
	Row: string;
	Quantity: number;
	Item_Price: number;
	SG_eDelivery: true;
	Company_Name: string;
	Is_AutoPriced: false;
}

type TSeasons = Array<ISeasonItem>;

const initialState = getInitialState<TSeasons | null>(null);

export const getSeasonData = createAsyncThunk<TSeasons, TSeasonsPayload, { rejectValue: string }>(
	"getSeasonInventory/getSeasonData",
	async ({ listingId }, { rejectWithValue }) => {
		try {
			const response = await ApiProvider.default.get<TSeasons>(`/api/AutoPricer/QueryInventorySeason?listingId=${listingId}`);
			return response;
		} catch (error) {
			toast.warning("Could not fetch seasons");
			if (error instanceof AxiosError) return rejectWithValue(error.message);
			return rejectWithValue("Unknown error");
		}
	},
);

export const getSeasonInventory = createSlice({
	name: "getSeasonInventory",
	initialState,
	reducers: {
		removeSeasonsData: (state) => {
			state.data = null;
			state.version = 0;
			state.error = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getSeasonData.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.data = null;
			})
			.addCase(getSeasonData.fulfilled, (state, action: PayloadAction<TSeasons>) => {
				state.loading = false;
				state.data = action.payload;
				state.version++;
				state.init = true;
			})
			.addCase(getSeasonData.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload ?? "Failed to fetch seasons";
				state.data = null;
			});
	},
});
