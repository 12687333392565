import React from "react";
import { Control, FieldValues, Path, useController } from "react-hook-form";
import styles from "./Textarea.module.scss";
import { clx } from "../utils/stringUtils";

export interface IInputProps<T extends FieldValues> extends React.ComponentProps<"textarea"> {
	control: Control<T>;
	name: Path<T>;
	label?: string;
	controlSize?: "n" | "sm";
	disableErrorMessage?: boolean;
}

export const Textarea = <T extends FieldValues>({
	label,
	controlSize: variant,
	control,
	name,
	disableErrorMessage,
	...rest
}: IInputProps<T>): React.ReactElement => {
	const { field, fieldState } = useController({ name, control });
	const errorMessage = fieldState.error?.message;
	const { isDirty, invalid } = fieldState;

	const innerTernary = field.value && isDirty ? "success" : "none";
	const inputStatus = invalid ? "error" : innerTernary;

	const requiredProps = {
		...rest,
		ref: field.ref,
		onBlur: field.onBlur,
		onChange: field.onChange,
		name: field.name,
		value: field.value ?? "",
		autoComplete: "off",
	};

	return (
		<div className={clx({ [styles.main]: true, [styles.small]: variant == "sm" })}>
			{label && <div className={styles.label}>{label}</div>}
			<textarea className={styles.textarea} data-input-status={inputStatus} {...requiredProps}>
				{field.value}
			</textarea>
			{!disableErrorMessage && errorMessage && (
				<div className={styles.status} data-input-status={inputStatus}>
					{errorMessage}
				</div>
			)}
		</div>
	);
};
