import React from "react";
import styles from "./Highlight.module.scss";

type THightlight = {
	isHighlighted?: boolean;
	children: React.ReactNode;
};

const Highlight: React.FC<THightlight> = ({ isHighlighted, children }) => {
	return (
		<div data-is-highlighted={isHighlighted} className={styles.root} style={{ padding: "0px" }}>
			{children}
		</div>
	);
};

export default Highlight;
