export const getElementCoords = (e: React.MouseEvent<HTMLElement>) => {
	const { currentTarget: target, clientX, clientY } = e;

	if (!target) {
		return {
			x: null,
			y: null,
		};
	}

	const x = clientX;
	const y = clientY;

	return { x, y };
};
