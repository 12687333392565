import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState = "";

export const { thunk: getAirbill, slice: getAirbillSlice } = getThunkAndSlice<
	{
		invoiceId: number;
		isAirbillGenerated: boolean;
	},
	typeof initialState
>({
	initialState,
	path: ({ invoiceId, isAirbillGenerated }) => `/api/fulfillment/getAirbillPDF/${invoiceId}?download=${isAirbillGenerated}`,
	method: "getFile",
	onAfterRequest: async (data, params, _state, _dispatch) => {
		if (params.isAirbillGenerated) {
			const anchor = document.createElement("a");
			anchor.download = `${params.invoiceId}.pdf`;
			anchor.href = data;
			anchor.click();

			// Cleanup
			anchor.remove();
		}

		return data;
	},
});
