import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState = false;

interface RequestPayload {
	[key: string]: string | number | undefined | null;
	listingId?: number | null;
	quantity?: number;
	eventName?: string | null;
	eventDate?: string;
	eventTime?: string | null;
	eventHeadliner?: string | null;
	catId?: number;
	section?: string;
	row?: string;
}

export const { thunk: forceAttachPdfs, slice: forceAttachPdfsSlice } = getThunkAndSlice<RequestPayload, typeof initialState>({
	initialState,
	path: "/api/System/ForceAttachPdfs",
	method: "postWithFilePdf",
});
