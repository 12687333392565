import helperStyles from "@src/components/helpers.module.scss";
import React, { CSSProperties } from "react";

type TArrowDownIcon = {
	color?: CSSProperties["color"];
};

export const ArrowDownIcon: React.FC<TArrowDownIcon> = ({ color }) => {
	return (
		<div className={helperStyles.iconContainer}>
			<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M2 4.5L7 9.5L12 4.5"
					stroke={color ?? "#667085"}
					strokeWidth="1.66667"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
};
