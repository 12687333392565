import React from "react";
import helperStyles from "@src/components/helpers.module.scss";
import { BarcodeIcon, PDFIcon, ShareIcon, TicketIcon } from "@src/assets/general-icons";
import { EventInventoryRecord } from "@src/store/pricing/queryEventInventoryAllSlice";
import { MyTooltip } from "@src/components/tooltip/Tooltip";

const Properties: React.FC<{ rowData: EventInventoryRecord }> = ({ rowData }) => {
	const id = `properties${rowData.Item_ID}`;

	return (
		<div className={helperStyles.flexSmallGap}>
			{rowData.Ticket_ID_Attached && (
				<MyTooltip
					id={`${id}ticket`}
					popup="Ticket"
					element={
						<span>
							<TicketIcon />
						</span>
					}
				/>
			)}
			{rowData.IsListingSharedWithGT && (
				<MyTooltip
					id={`${id}share`}
					popup="Share"
					element={
						<span>
							<ShareIcon />
						</span>
					}
				/>
			)}
			{rowData.PDF_Attached && (
				<MyTooltip
					id={`${id}pdf`}
					popup="PDF"
					element={
						<span>
							<PDFIcon />
						</span>
					}
				/>
			)}
			{rowData.Barcode_Attached && (
				<MyTooltip
					id={`${id}barcode`}
					popup="Barcode"
					element={
						<span>
							<BarcodeIcon />
						</span>
					}
				/>
			)}
		</div>
	);
};

export default Properties;
