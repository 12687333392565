interface CalculatePriceData {
	ceilingPrice: number;
	floorPrice: number;
	adjustment: number;
	isPercentage: boolean;
	lowestComparablePrice: number;
	currentPrice: number;
}

export const calculatePrice = (data: CalculatePriceData): number | null => {
	const { ceilingPrice, floorPrice, adjustment, isPercentage, lowestComparablePrice, currentPrice } = data;

	if (!ceilingPrice || !floorPrice) return null;
	if (ceilingPrice < floorPrice || floorPrice > ceilingPrice) return null;
	if (!lowestComparablePrice) return currentPrice;

	const onePercent = lowestComparablePrice * 0.01;
	const newAdjustment = isPercentage ? onePercent * adjustment : adjustment;

	let newPrice = lowestComparablePrice + newAdjustment;

	if (newPrice > ceilingPrice) newPrice = ceilingPrice;
	if (newPrice < floorPrice) newPrice = floorPrice;

	return newPrice;
};

export const calculatePossibleSplits = (payload: Array<number> | undefined, quantity: number): Array<number> => {
	const maxQuantity = 8;
	let adjustedQuantity = quantity;
	if (quantity > maxQuantity) adjustedQuantity = maxQuantity;

	if (!payload || adjustedQuantity < 1 || adjustedQuantity > payload.length) {
		return [];
	}

	const value = payload[adjustedQuantity - 1];
	const splits = [];

	for (let i = 0; i < adjustedQuantity; i++) {
		if (value & (1 << i)) {
			splits.push(i + 1);
		}
	}

	return splits;
};
