import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { EventRecord } from "./queryEventsSlice";

export enum InventoryActionEnum {
	Select = "Select",
	Edit = "Edit",
}

export enum ManageInventoryTabEnum {
	Autopricer = "Autopricer",
	ManageListing = "ManageListing",
}

export enum SelectedManageInventoryTabEnum {
	Basic = "Basic",
	Advanced = "Advanced",
}

export enum HighlightedSectionEnum {
	ListingInfo = "Listing Info",
	ShareUnshare = "Share/Unshare",
	Tickets = "Tickets",
	InternalNotes = "Internal Notes",
	ExternalNotes = "External Notes",
	AttachPDF = "Attach PDF's",
	SchedulePrice = "Schedule Price",
	FlashSale = "Flash Sale",
	PurchaseInformation = "Purchase Information",
	AddTag = "Add Tag",
	SplitListing = "Split Listing",
}

export enum SalesHighlightedSectionEnum {
	UpdateCost = "Update Cost",
	Tickets = "Tickets",
	InternalNotes = "Internal Notes",
	AttachPDF = "Attach PDF's",
	PaymentHistory = "Payment History",
	Invoice = "Invoice",
}

interface ISelectedEvent {
	event: EventRecord | null;
	showComingSoon: boolean;
	inventoryHasChanges: boolean;
	inventoryAction: InventoryActionEnum;
	manageInventoryTab: ManageInventoryTabEnum;
	selectedInventoryTab: SelectedManageInventoryTabEnum;
	viewSeasonLowestComparables: boolean;
	inventoryItemIds: Array<number>;
	highlightedSection: string | null;
	openTagModalWithId: number | null;
}

const initialState: ISelectedEvent = {
	event: null,
	showComingSoon: false,
	inventoryHasChanges: false,
	inventoryAction: InventoryActionEnum.Select,
	manageInventoryTab: ManageInventoryTabEnum.ManageListing,
	selectedInventoryTab: SelectedManageInventoryTabEnum.Basic,
	viewSeasonLowestComparables: false,
	inventoryItemIds: [],
	highlightedSection: null,
	openTagModalWithId: null,
};

export const selectionSlice = createSlice({
	name: "selectionSlice",
	initialState,
	reducers: {
		setInventoryHasChanges: (state, action: PayloadAction<{ inventoryHasChanges: boolean }>) => {
			return {
				...state,
				...action.payload,
			};
		},
		setEvent: (state, action: PayloadAction<{ event: EventRecord | null }>) => {
			return {
				...state,
				...action.payload,
			};
		},
		setInventory: (
			state,
			action: PayloadAction<{ inventoryItemIds?: Array<number>; inventoryAction?: InventoryActionEnum }>,
		) => {
			return {
				...state,
				...action.payload,
			};
		},
		setManageInventoryTab: (state, action: PayloadAction<ManageInventoryTabEnum>) => {
			state.manageInventoryTab = action.payload;
		},
		setSelectedInventoryTab: (state, action: PayloadAction<SelectedManageInventoryTabEnum>) => {
			state.selectedInventoryTab = action.payload;
		},
		reset: (state) => {
			state.event = null;
			state.inventoryItemIds = [];
		},
		showComingSoon: (state, action: PayloadAction<{ showComingSoon: boolean }>) => {
			return {
				...state,
				...action.payload,
			};
		},
		setViewSeasonLowestComparables: (state, action: PayloadAction<boolean>) => {
			state.viewSeasonLowestComparables = action.payload;
		},
		setHighlightedSection: (state, action: PayloadAction<HighlightedSectionEnum | SalesHighlightedSectionEnum | null>) => {
			state.highlightedSection = action.payload;
		},
		setTagModalId: (state, action: PayloadAction<ISelectedEvent["openTagModalWithId"]>) => {
			state.openTagModalWithId = action.payload;
		},
	},
});

export const selectedInventoryItemIds = (state: RootState) => state.pricing.selection.inventoryItemIds;
export const selectViewSeasonLowestComparables = (state: RootState) => state.pricing.selection.viewSeasonLowestComparables;
