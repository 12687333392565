import { AxiosError } from "axios";
import { ApiProvider } from "../ApiProvider";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

type TInitialState = {
	isLoading: boolean;
	isInitialized: boolean;
	error: string | null;
};

export type TPayload = {
	cc_emails: Array<string>;
	description: string;
	email: string;
	subject: string;
};

const initialState: TInitialState = {
	isLoading: false,
	isInitialized: false,
	error: null,
};

export const createHelpAndSupportTicket = createAsyncThunk<unknown, TPayload>(
	"helpAndSupport/createHelpAndSupportTicket",
	async (payload, { rejectWithValue }) => {
		try {
			return await ApiProvider.default.post("/api/System/CreateFreshDeskSupportTicket", payload);
		} catch (error) {
			if (error instanceof AxiosError) return rejectWithValue(error.message);
			else return rejectWithValue("Unknown error");
		}
	},
);

export const helpAndSupportSlice = createSlice({
	name: "helpAndSupport",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(createHelpAndSupportTicket.pending, (state) => {
				state.isLoading = true;
				state.isInitialized = true;
			})
			.addCase(createHelpAndSupportTicket.fulfilled, (state) => {
				state.isLoading = false;
			})
			.addCase(createHelpAndSupportTicket.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.payload as string;
			});
	},
});
