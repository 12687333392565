import { DateFormatsEnum, formatDate } from "@src/components/utils/dateUtils";
import { EventInventoryRecord } from "@src/store/pricing/queryEventInventoryAllSlice";
import { EventRecord } from "@src/store/pricing/queryEventsSlice";
import { TPayload } from "@src/store/system/helpAndSupportSlice";

type TConstructPayload = {
	selectedEvent: EventRecord;
	nameId: string | null;
	emails: Array<string>;
	commonHelp: string;
	additionalInfo?: string;
	userCompanyName: string;
	selectedRow: EventInventoryRecord;
};

export const constructSupportPayload = (data: TConstructPayload): TPayload => ({
	cc_emails: data.emails,
	description: `<p>User Name: ${data.nameId}</p><p>Company Name: ${data.userCompanyName}</p><br /><p>Listing Information</p><ul><li>Event: ${data.selectedEvent?.Event_Headliner}</li><li>Date: ${formatDate(data.selectedEvent?.Event_Date, DateFormatsEnum["MM/DD/YYYY"])}</li><li>Time: ${data.selectedEvent?.Event_Time}</li><li>Venue: ${data.selectedEvent?.Venue_Name}</li><li>Section: ${data.selectedRow?.Section}</li><li>Row: ${data.selectedRow?.Row}</li><li>Seats: ${data.selectedRow?.Seats}</li></ul><br /><p>Additional Information</p><p>${data.additionalInfo ?? "/"}</p><a href="https://portal.stagefront.com/Pricing#listing/${data.selectedRow?.Listing_ID}">Go to Listing</a>`,
	email: data.emails[0],
	subject: `[Portal Re-Design] Issue - ${data.commonHelp} (Listing ID: ${data.selectedRow?.Listing_ID})`,
});
