import { pricingActions } from "@src/store";
import React, { CSSProperties } from "react";
import { useDispatch } from "react-redux";
import { Button, ButtonVariant } from "../button/Button";
import { allMarketplaces } from "../utils/marketplaceUtils";
import { MixpanelCategory, mixpanelTrack } from "../utils/mixPanelUtils";
import { clx } from "../utils/stringUtils";
import styles from "./MarketPlaces.module.scss";
import { MyTooltip } from "../tooltip/Tooltip";
import { useAppSelector } from "@src/store/hooks";

export const MarketPlaces: React.FC<{
	items: Array<number>;
	wrapperStyle?: CSSProperties;
}> = ({ wrapperStyle, items }) => {
	const { data } = useAppSelector((state) => state.pricing.sharingSettings);
	const dispatch = useDispatch();

	const toggleAll = () => {
		const currentActiveIds = [...data.ids];

		const nonDisabledMarketplaceIds = allMarketplaces
			.filter((marketplace) => !marketplace.isDisabled)
			.map((marketplace) => marketplace.id);

		const activeDisabledMarketplaceIds = allMarketplaces
			.filter((marketplace) => marketplace.isDisabled && currentActiveIds.includes(marketplace.id))
			.map((marketplace) => marketplace.id);

		const newIds = [...nonDisabledMarketplaceIds, ...activeDisabledMarketplaceIds];

		dispatch(pricingActions.setSavedRuleIds(newIds));
	};

	return (
		<div className={styles.main} style={wrapperStyle}>
			<div className={styles.headline}>Marketplaces</div>
			<div className={styles.content}>
				<div className={styles.allButton}>
					<Button onClick={toggleAll} variant={ButtonVariant.Tertiary} className={styles.item}>
						All
					</Button>
				</div>
				<div className={styles.icons}>
					{allMarketplaces.map((item) => {
						const isSelected = items.includes(item.id);
						const handleClick = () => {
							dispatch(pricingActions.toggleRule(item.id));
							mixpanelTrack(MixpanelCategory.MARKETPLACE_TOGGLED, {
								data: { marketplace: item.name, state: !isSelected ? "Selected" : "Unselected" },
							});
						};

						const buttonElement = (
							<Button
								key={item.id}
								onClick={handleClick}
								disabled={item.isDisabled}
								className={clx({ [styles.selected]: isSelected, [styles.item]: true })}>
								{item.icon}
							</Button>
						);

						if (!item.isDisabled) return buttonElement;

						return (
							<MyTooltip
								key={item.id}
								id={`share_unshare_tooltip_${item.id}`}
								popup="Coming soon"
								element={buttonElement}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
};
