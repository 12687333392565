import { SortField } from "@src/store/helpers";
import { ApiProvider } from "@src/store/ApiProvider";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

export interface QuerySalesPayload {
	SearchTag?: string;
	BuyerPOID?: string;
	CategoryId?: number;
	CompanyName?: string;
	EventFromDate?: Date | string;
	EventHeadlinerId?: number;
	EventId?: number;
	EventToDate?: Date | string;
	ExchangeName?: string;
	ForceRefresh?: boolean;
	InvoiceId?: number;
	InvoiceIds?: Array<number>;
	Limit?: number;
	OrderByColumn?: Array<SortField>;
	Page?: number;
	PaymentFromDate?: Date | string;
	PaymentToDate?: Date | string;
	Quantity?: number;
	Rows?: string;
	SaleFromDate?: Date | string;
	SaleStatus?: string;
	SaleToDate?: Date | string;
	SearchString?: string;
	Sections?: string;
	StartIndex?: number;
	VenueId?: number;
}

export interface SalesInfo {
	Airbill_Date: string | null;
	Airbill_User: string | null;
	Automation_Notes: string | null;
	Buyer_PO_ID: string | null;
	Cancelled_Date: string | null;
	Cat_Desc: string | null;
	Commission_Amount: number | null;
	Commission_Rate: number | null;
	Company_Name: string;
	Completed_Date: string | null;
	Cost_Locked: boolean | null;
	Event_ID: number | null;
	Event_Name: string | null;
	Event_Headliner: string | null;
	Event_Date: string | null;
	Event_Time: string | null;
	Event_Tags: string | null;
	Exchange_Name: string;
	External_Notes: string;
	InHand: boolean | null;
	InHandDate: string | null;
	Internal_Notes: string;
	Invoice_Amount: number;
	Invoice_ID: number;
	Is_eDelivery: boolean;
	Item_Price: number;
	Item_Tags: string | null;
	Listing_ID: number | null;
	Num_Barcodes: number;
	Num_PDFs: number;
	PO_Date: string | null;
	PO_ID: number;
	Payment_Amount: number | null;
	Payment_Date: string | null;
	Payment_Details: string | null;
	Payment_Notes: string | null;
	Payment_Type: string | null;
	PdfsAttached: number;
	Quantity: number;
	ROI: number;
	ROIPercent: number;
	Row: string;
	Sale_Date: string | null;
	Sale_Time: string | null;
	Sale_Status: string;
	Sale_Type: string;
	Seat_From: number | null;
	Seat_Thru: number | null;
	Section: string;
	Shipping_Comments: string | null;
	Tickets_Available_Date: string | null;
	Total_Cost: number;
	Venue_City: string;
	Venue_Name: string;
	Venue_State: string;
}

interface SalesState {
	data: Array<SalesInfo>;
	status: "idle" | "loading" | "succeeded" | "failed";
	error: string | null;
}

const initialState: SalesState = {
	data: [],
	status: "idle",
	error: null,
};

export const fetchSales = createAsyncThunk<Array<SalesInfo>, QuerySalesPayload>(
	"sales/fetchSales",
	async (payload, { rejectWithValue }) => {
		try {
			const url = `api/Sales/QuerySales`;
			return await ApiProvider.default.post<Array<SalesInfo>>(url, payload);
		} catch (error) {
			if (error instanceof AxiosError) return rejectWithValue(error.message);
			else return rejectWithValue("Unknown error");
		}
	},
);

export const salesSlice = createSlice({
	name: "sales",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchSales.pending, (state) => {
				state.status = "loading";
				state.error = null;
			})
			.addCase(fetchSales.fulfilled, (state, action: PayloadAction<Array<SalesInfo>>) => {
				state.status = "succeeded";
				state.data = action.payload;
			})
			.addCase(fetchSales.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.payload as string;
			});
	},
});
