import React, { useEffect } from "react";
import { Toaster } from "sonner";
import "./global.module.scss";
import { Routes } from "./routes/Routes";
import "./styles/styles.scss";
import { useAppSelector } from "@src/store/hooks";
import mixpanel from "mixpanel-browser";

export const App: React.FC = () => {
	const theme = useAppSelector((state) => state.settings.theme);
	const userEmail = useAppSelector((state) => state.settings.email);

	useEffect(() => {
		if (!userEmail) return;
		mixpanel.identify(userEmail);
	}, [userEmail]);

	return (
		<div className={`theme--${theme}`}>
			<Routes />
			<Toaster duration={2000} position="top-center" expand={false} closeButton />
			<div id="modal"></div>
		</div>
	);
};
