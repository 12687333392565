// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdvancedTab-module_leftContainer___aIvr2,
.AdvancedTab-module_rightContainer___owy1z {
  position: relative;
}
.AdvancedTab-module_leftContainer___aIvr2[data-is-sold=true]::after,
.AdvancedTab-module_rightContainer___owy1z[data-is-sold=true]::after {
  content: "";
  position: absolute;
  top: -8px;
  width: calc(100% + 16px);
  height: calc(100% + 16px);
  border-radius: 4px;
  left: -8px;
  cursor: not-allowed;
  background-color: rgba(229, 233, 235, 0.537254902);
}
.AdvancedTab-module_leftContainer___aIvr2 .AdvancedTab-module_main___SVJ0b,
.AdvancedTab-module_rightContainer___owy1z .AdvancedTab-module_main___SVJ0b {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.AdvancedTab-module_subHeaderContainer___onlaf {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.AdvancedTab-module_subHeaderContainer___onlaf .AdvancedTab-module_left___cFBf5 {
  display: flex;
  align-items: center;
  gap: 8px;
}
.AdvancedTab-module_subHeaderContainer___onlaf .AdvancedTab-module_right___mVcfv {
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/children/pricing/components/manage-inventory/manage-listing/AdvancedTab/AdvancedTab.module.scss"],"names":[],"mappings":"AAAA;;EAEC,kBAAA;AACD;AACC;;EACC,WAAA;EACA,kBAAA;EACA,SAAA;EACA,wBAAA;EACA,yBAAA;EACA,kBAAA;EACA,UAAA;EACA,mBAAA;EACA,kDAAA;AAEF;AACC;;EACC,aAAA;EACA,sBAAA;EACA,SAAA;AAEF;;AAEA;EACC,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,QAAA;AACD;AACC;EACC,aAAA;EACA,mBAAA;EACA,QAAA;AACF;AAEC;EACC,6BAAA;AAAF","sourcesContent":[".leftContainer,\r\n.rightContainer {\r\n\tposition: relative;\r\n\r\n\t&[data-is-sold=\"true\"]::after {\r\n\t\tcontent: \"\";\r\n\t\tposition: absolute;\r\n\t\ttop: -8px;\r\n\t\twidth: calc(100% + 16px);\r\n\t\theight: calc(100% + 16px);\r\n\t\tborder-radius: 4px;\r\n\t\tleft: -8px;\r\n\t\tcursor: not-allowed;\r\n\t\tbackground-color: #e5e9eb89;\r\n\t}\r\n\r\n\t.main {\r\n\t\tdisplay: flex;\r\n\t\tflex-direction: column;\r\n\t\tgap: 32px;\r\n\t}\r\n}\r\n\r\n.subHeaderContainer {\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\tjustify-content: space-between;\r\n\tgap: 8px;\r\n\r\n\t.left {\r\n\t\tdisplay: flex;\r\n\t\talign-items: center;\r\n\t\tgap: 8px;\r\n\t}\r\n\r\n\t.right {\r\n\t\tbackground-color: transparent;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leftContainer": `AdvancedTab-module_leftContainer___aIvr2`,
	"rightContainer": `AdvancedTab-module_rightContainer___owy1z`,
	"main": `AdvancedTab-module_main___SVJ0b`,
	"subHeaderContainer": `AdvancedTab-module_subHeaderContainer___onlaf`,
	"left": `AdvancedTab-module_left___cFBf5`,
	"right": `AdvancedTab-module_right___mVcfv`
};
export default ___CSS_LOADER_EXPORT___;
