import { eventActions } from "@src/store";
import React, { MouseEvent } from "react";
import { MixpanelCategory, mixpanelTrack } from "../utils/mixPanelUtils";
import { InventoryActionEnum, ManageInventoryTabEnum } from "@src/store/pricing/selectionSlice";
import { useAppDispatch } from "@src/store/hooks";
import { Button, ButtonVariant } from "../button/Button";

export const EditInventory: React.FC<{
	itemId: number;
}> = ({ itemId }) => {
	const dispatch = useAppDispatch();

	const handleEditButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
		dispatch(
			eventActions.setInventory({
				inventoryItemIds: [itemId],
				inventoryAction: InventoryActionEnum.Edit,
			}),
		);
		dispatch(eventActions.setManageInventoryTab(ManageInventoryTabEnum.ManageListing));
		e.stopPropagation();

		mixpanelTrack(MixpanelCategory.EDIT_INVENTORY_CLICKED, {
			data: itemId,
		});
	};

	return (
		<Button
			style={{ minHeight: 18 }}
			variant={ButtonVariant.Secondary}
			controlSize="xsm"
			tabIndex={0}
			onClick={handleEditButtonClick}>
			Edit
		</Button>
	);
};
