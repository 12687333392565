import { XIcon } from "@src/assets/general-icons";
import React from "react";
import { clx } from "../utils/stringUtils";
import styles from "./CommonModal.module.scss";

type IAllFilters = {
	controlSize?: "xsm" | "sm" | "msm" | "m" | "l" | "xl";
	title?: React.ReactElement | string;
	closeModal?: () => void;
	content?: React.ReactElement | string;
	buttons?: React.ReactElement | string;
	boldedHeader?: boolean;
} & Omit<React.ComponentProps<"div">, "content">;

export const CommonModal: React.FC<IAllFilters> = ({
	controlSize = "m",
	title,
	content,
	buttons,
	closeModal,
	boldedHeader = false,
	...rest
}) => {
	return (
		<div className={clx(styles.main, styles[controlSize])}>
			<div data-is-bold={boldedHeader} className={styles.header}>
				<div style={{ whiteSpace: "nowrap" }}>{title}</div>
				<div aria-hidden="true" style={{ display: "flex", alignItems: "center" }} onClick={closeModal}>
					<XIcon color="white" />
				</div>
			</div>
			<div className={styles.content} {...rest}>
				{content}
			</div>
			{buttons ? <div className={styles.buttons}>{buttons}</div> : null}
		</div>
	);
};
