import React from "react";
import styles from "./HelpAndSupport.module.scss";
import { Button, ButtonVariant } from "../button/Button";
import { SubHeader } from "@src/pages/home/children/pricing/components/manage-inventory/manage-listing/AdvancedTab/components/SubHeader/SubHeader";
import SpacedLabelValue from "./SpacedLabelValue";
import { DateFormatsEnum, formatDate } from "../utils/dateUtils";
import { EventInventoryRecord } from "@src/store/pricing/queryEventInventoryAllSlice";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SelectYup } from "../select/SelectYup";
import { HelpAndSupportValidation, HelpAndSupportValidationType } from "@src/validation/HelpAndSupportValidation";
import { Textarea } from "../textarea/Textarea";
import { options } from "./options";
import { constructSupportPayload } from "@src/utils/helpAndSupport.utils";
import { toast } from "sonner";
import { systemActions } from "@src/store";

type THelpAndSupport = {
	selectedRow: EventInventoryRecord | null;
	closeContextMenu: () => void;
};

const HelpAndSupport: React.FC<THelpAndSupport> = ({ selectedRow, closeContextMenu }) => {
	const selectedEvent = useAppSelector((state) => state.pricing.selection.event);
	const formattedEventDate = formatDate(selectedEvent?.Event_Date, DateFormatsEnum["MM/DD/YYYY"]);
	const isLoading = useAppSelector((state) => state.system.helpAndSupport.isLoading);
	const { nameId, email, userCompanyName } = useAppSelector((state) => state.settings);

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(HelpAndSupportValidation),
	});

	const hasCommonHelpError = errors?.commonHelp?.message;
	const dispatch = useAppDispatch();

	const onSubmit: SubmitHandler<HelpAndSupportValidationType> = async (data) => {
		const { commonHelp, additionalInfo } = data;

		if (!email) {
			toast.warning("Email doesn't exist");
			return;
		}

		if (!selectedEvent || !selectedRow || !userCompanyName) {
			toast.warning("Necessary data unavailable");
			return;
		}

		const payload = constructSupportPayload({
			commonHelp,
			additionalInfo,
			selectedEvent,
			nameId,
			userCompanyName,
			selectedRow,
			emails: [email],
		});

		const response = await dispatch(systemActions.createHelpAndSupportTicket(payload));

		if (response.meta.requestStatus === "fulfilled") {
			closeContextMenu();
		} else {
			toast.warning("Error while sending a support ticket.");
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={styles.root}>
			<div className={styles.content}>
				<div className={styles.listing}>
					<SubHeader title="Selected Listing" tooltipText="Selected listing and event information" />
					<div className={styles.listingsContent}>
						<SpacedLabelValue label="Event:" value={selectedEvent?.Event_Headliner} />
						<SpacedLabelValue label="Date:" value={formattedEventDate} />
						<SpacedLabelValue label="Time:" value={selectedEvent?.Event_Time} />
						<SpacedLabelValue label="Venue:" value={selectedEvent?.Venue_Name} />
						<SpacedLabelValue label="Section:" value={selectedRow?.Section} />
						<SpacedLabelValue label="Row:" value={selectedRow?.Row} />
						<SpacedLabelValue label="Seats:" value={selectedRow?.Seats} />
					</div>
				</div>
				<div className={styles.description}>
					<SubHeader title="Description" tooltipText="Your support description" />
					<div className={styles.descriptionContent}>
						<div className={styles.headline}>Common Help</div>
						<div className={styles.commonHelp}>
							<SelectYup control={control} name="commonHelp" options={options} />
							{hasCommonHelpError ? <div className={styles.commonHelpError}>{hasCommonHelpError}</div> : null}
						</div>
						<div className={styles.headline}>Additional info (optional)</div>
						<Textarea control={control} name="additionalInfo" />
					</div>
				</div>
			</div>
			<div className={styles.buttons}>
				<Button onClick={closeContextMenu} variant={ButtonVariant.Quaternary}>
					Cancel
				</Button>
				<Button disabled={isLoading} isLoading={isLoading} type="submit" variant={ButtonVariant.Secondary}>
					Submit
				</Button>
			</div>
		</form>
	);
};

export default HelpAndSupport;
