import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import _ from "lodash";
import { ApiProvider } from "./ApiProvider";
import { ColumnListType, defaultEventColumns, defaultInventoryColumns } from "./pricing/settingsSlice";

const getStorage = (rememberMe: boolean) => (rememberMe ? localStorage : sessionStorage);
const getSetting = <T>(key: string, defaultValue: T) => {
	const localStorageValue = localStorage.getItem(key);
	const sessionStorageValue = sessionStorage.getItem(key);
	// TODO: try to read it from cookie
	return localStorageValue || sessionStorageValue || defaultValue;
};
const saveSetting = (key: string, value: string | boolean | null, rememberMe = false) => {
	const storage = getStorage(rememberMe);
	if (value === null) {
		storage.removeItem(key);
		return;
	}
	storage.setItem(key, `${value}`);
};
export enum Theme {
	Light = "light",
	Dark = "dark",
}
type CustomizeTableType = "Event" | "Inventory";
export interface IPreset {
	name: string;
	columns: ColumnListType;
}
const initialState: {
	token: string;
	portalSidebarExpanded: boolean;
	portalSidebarIsHidden: boolean;
	expandEvents: boolean;
	expandInventory: boolean;
	editInventory: boolean;
	customizeTable: {
		selectedTab: CustomizeTableType;
		Event: {
			presets: Array<IPreset>;
			selectedPreset: string;
			current: ColumnListType;
		};
		Inventory: {
			presets: Array<IPreset>;
			selectedPreset: string;
			current: ColumnListType;
		};
	};
	theme: Theme;
	nameId: string | null;
	showCustomizeTable: boolean;
	companyName: string;
	email: string;
	isAdmin: boolean;
	isOldPortal: boolean;
} = {
	token: getSetting("token", ""),
	companyName: "Partnerships",
	isAdmin: false,
	email: "",
	isOldPortal: JSON.parse(getSetting("portalIsOldPortal", "true")),
	portalSidebarExpanded: JSON.parse(getSetting("portalSidebarExpanded", "false")),
	portalSidebarIsHidden: JSON.parse(getSetting("portalSidebarIsHidden", "false")),
	expandEvents: JSON.parse(getSetting("expandEvents", "false")),
	expandInventory: JSON.parse(getSetting("expandInventory", "false")),
	editInventory: false,
	customizeTable: {
		selectedTab: "Event",
		Event: {
			presets: JSON.parse(
				getSetting("pricingEventPresets", JSON.stringify([{ name: "Default", columns: defaultEventColumns }])),
			),
			current: JSON.parse(getSetting("pricingEventColumns", JSON.stringify(defaultEventColumns))),
			selectedPreset: "Default",
		},
		Inventory: {
			presets: JSON.parse(
				getSetting("pricingInventoryPresets", JSON.stringify([{ name: "Default", columns: defaultInventoryColumns }])),
			),
			current: JSON.parse(getSetting("pricingInventoryColumns", JSON.stringify(defaultInventoryColumns))),
			selectedPreset: "Default",
		},
	},
	theme: (() => {
		switch (getSetting("portal_theme", Theme.Light)) {
			case Theme.Dark:
				return Theme.Dark;
			case Theme.Light:
			default:
				return Theme.Light;
		}
	})(),
	showCustomizeTable: false,
	nameId: null,
};

export const settingsSlice = createSlice({
	name: "settings",
	initialState,
	reducers: {
		setToken(state, action: PayloadAction<{ token: string; rememberMe: boolean }>) {
			const { token, rememberMe } = action.payload;
			try {
				const decoded = jwtDecode<{ Company_Name: string; IsAdmin: string; Email: string; nameid: string }>(token);
				if (decoded && typeof decoded === "object") {
					state.isAdmin = decoded.IsAdmin == "True";
					state.nameId = decoded.nameid;
					state.companyName = decoded.Company_Name || "";
					state.email = decoded.Email || "";
				}
				const savedCompanyName = getSetting("savedCompanyName", decoded.Company_Name);
				if (state.isAdmin && savedCompanyName) {
					state.companyName = savedCompanyName;
				}
				saveSetting("token", token, rememberMe);
				state.token = token;
				ApiProvider.default.setToken(token);
			} catch (error) {
				console.error("Failed to decode JWT token:", error);
			}
		},
		setCompanyName(state, action: PayloadAction<string>) {
			state.companyName = action.payload;
			saveSetting("savedCompanyName", action.payload, true);
		},
		clearToken(state) {
			saveSetting("token", null, false);
			saveSetting("token", null, true);
			return {
				...state,
				token: "",
			};
		},
		setPortalSidebarExpanded(state, action: PayloadAction<boolean>) {
			saveSetting("portalSidebarExpanded", action.payload, true);
			return {
				...state,
				portalSidebarExpanded: action.payload,
			};
		},
		setPricingEventsColumns(state, action: PayloadAction<ColumnListType>) {
			saveSetting("pricingEventColumns", JSON.stringify(action.payload), true);
			state.customizeTable.Event.current = action.payload;
		},
		setPricingInventoryColumns(state, action: PayloadAction<ColumnListType>) {
			saveSetting("pricingInventoryColumns", JSON.stringify(action.payload), true);
			state.customizeTable.Inventory.current = action.payload;
		},
		selectPreset(state, action: PayloadAction<{ type: CustomizeTableType; preset: IPreset }>) {
			const { type, preset } = action.payload;
			state.customizeTable[type].current = preset.columns;
			state.customizeTable[type].selectedPreset = preset.name;
		},

		removePreset(state, action: PayloadAction<{ type: CustomizeTableType; preset: IPreset }>) {
			const { type, preset } = action.payload;
			const presets = state.customizeTable[type].presets;
			const presetIndex = _.findIndex(presets, (p) => p.name === preset.name);
			if (presetIndex !== -1) {
				presets.splice(presetIndex, 1);
			}
			saveSetting(`pricing${type}Presets`, JSON.stringify(presets), true);
		},
		addPreset(state, action: PayloadAction<{ type: CustomizeTableType; preset: IPreset }>) {
			const { type, preset } = action.payload;
			const presets = state.customizeTable[type].presets;

			const presetIndex = _.findIndex(presets, (p) => p.name === preset.name);

			if (presetIndex === -1) {
				presets.push(preset);
			} else {
				presets[presetIndex] = preset;
			}
			saveSetting(`pricing${type}Presets`, JSON.stringify(presets), true);
		},
		setPortalSidebarIsHidden(state, action: PayloadAction<boolean>) {
			saveSetting("portalSidebarIsHidden", action.payload, true);
			return {
				...state,
				portalSidebarIsHidden: action.payload,
			};
		},
		setExpandEvent(state, action: PayloadAction<boolean>) {
			saveSetting("expandEvents", action.payload, true);
			return {
				...state,
				expandEvents: action.payload,
			};
		},
		setExpandInventory(state, action: PayloadAction<boolean>) {
			saveSetting("expandInventory", action.payload, true);
			return {
				...state,
				expandInventory: action.payload,
			};
		},
		setEditInventory(state, action: PayloadAction<boolean>) {
			return {
				...state,
				editInventory: action.payload,
			};
		},
		changeTheme: (state, action: { payload: Theme }) => {
			saveSetting("portal_theme", action.payload, true);
			return {
				...state,
				theme: action.payload,
			};
		},
		setShowCustomizeTable: (state, action: PayloadAction<boolean>) => {
			state.showCustomizeTable = action.payload;
		},
		setCustomizeTableTab: (state, action: PayloadAction<CustomizeTableType>) => {
			state.customizeTable.selectedTab = action.payload;
			state.showCustomizeTable = true;
		},
		toggleOldPortal: (state) => {
			saveSetting("portalIsOldPortal", !state.isOldPortal);
			state.isOldPortal = !state.isOldPortal;
		},
	},
});
