import { ActionReducerMapBuilder, createAsyncThunk, createSlice, Draft } from "@reduxjs/toolkit";
import { MixpanelCategory, mixpanelTrack } from "@src/components/utils/mixPanelUtils";
import { UNKNOWN } from "@src/components/vGrid/VGrid";
import axios, { AxiosError } from "axios";
import { ApiProvider } from "./ApiProvider";
import { getInitialState, IDataState } from "./helpers";

type WithSkipCache<T> = T & { skipCache?: boolean };
type IExtraReducers<TState> = (builder: ActionReducerMapBuilder<IDataState<TState>>) => void;
export const getThunkAndSlice = <TParams, TResponse, TState = TResponse>({
	externalPath,
	path,
	initialState,
	cacheId,
	responsePath,
	method = "get",
	onBeforeRequest,
	onAfterRequest,
	onData,
}: {
	externalPath?: (params: TParams) => string;
	path: string | ((params: TParams) => string);
	initialState: TState;
	cacheId?: keyof TParams;
	method?: "post" | "get" | "postWithFile" | "getFile" | "delete" | "postWithFilePdf";
	responsePath?: keyof TResponse;
	onBeforeRequest?: (params: TParams, state: unknown) => TState | undefined;
	onAfterRequest?: (data: TState, params: TParams, state: unknown, dispatch: UNKNOWN) => Promise<TState | undefined>;
	onData?: (data: TState) => void;
}) => {
	const iState = getInitialState(initialState);

	const getCacheKey = (params: TParams, cacheId?: keyof TParams): string => {
		if (!params || !cacheId) return "";
		return String(params[cacheId]);
	};

	const handleError = (error: unknown) => {
		if (error instanceof AxiosError) {
			let responseError = error.response?.data ?? error.response?.data?.Message;
			const statusCode = error.response?.status;
			if (statusCode === 429) {
				responseError = "Too many requests, please try again in a minute";
				mixpanelTrack(MixpanelCategory.ERROR_429, {
					data: responseError || error.message,
					path: error?.request?.responseURL || "No path",
				});
			} else {
				mixpanelTrack(MixpanelCategory.API_ERROR, {
					data: responseError || error.message,
					path: error?.request?.responseURL || "No path",
				});
			}

			return responseError || error.message;
		} else if (error instanceof Error) {
			return error.message;
		}

		return "Unknown error";
	};

	const pathKey = (typeof path === "function" ? path({} as TParams) : path).replace(/\//g, "_");

	const thunk = createAsyncThunk<TState, WithSkipCache<TParams>>(pathKey, async (params, opts) => {
		const { rejectWithValue, getState, dispatch } = opts;
		try {
			const state = getState();

			const result = onBeforeRequest?.(params, state);
			if (result) return result;

			const pathString = typeof path === "function" ? path(params) : path;
			const cacheKey = getCacheKey(params, cacheId);

			if (!params.skipCache) {
				const cached = ApiProvider.default.getCache<TState>(pathKey, cacheKey);
				if (cached) return cached;
			}

			mixpanelTrack(MixpanelCategory.API_REQUEST_TRIGGERED, {
				method,
				pathString,
				params,
			});

			let provider;

			if (externalPath) {
				const pathString = externalPath(params);
				provider = (await axios.get<TResponse>(pathString)).data;
			} else {
				provider = ApiProvider.default[method]<TResponse>(pathString, params || {});
			}

			const response = await provider;
			let data = (responsePath && typeof response == "object" ? response?.[responsePath] : response) as TState;

			const newData = await onAfterRequest?.(data, params, state, dispatch);
			if (newData) data = newData;

			ApiProvider.default.setCache(pathKey, cacheKey, data);
			return data;
		} catch (error: unknown) {
			return rejectWithValue(handleError(error));
		}
	});

	const extraReducers: IExtraReducers<TState> = (builder) => {
		builder
			.addCase(thunk.pending, (state) => {
				state.loading = true;
			})
			.addCase(thunk.fulfilled, (state, action) => {
				state.loading = false;
				state.version++;
				state.init = true;
				state.data = action.payload as Draft<TState>;
				onData?.(action.payload);
				state.error = null;
			})
			.addCase(thunk.rejected, (state, action) => {
				state.loading = false;
				state.data = iState.data as Draft<TState>;
				const payload = action.payload as UNKNOWN;
				if (action.error?.message) {
					state.error = action.error.message;
				} else if (payload.Message) {
					state.error = payload.Message;
				} else if (typeof payload === "string") {
					state.error = action.payload as string;
				} else {
					state.error = JSON.stringify(action.payload);
				}
			});
	};

	const slice = createSlice({
		name: pathKey,
		initialState: iState,
		reducers: {
			reset: (state) => {
				state.data = iState.data as Draft<TState>;
			},
			update: (state, data) => {
				state.data = data.payload as Draft<TState>;
			},
		},
		extraReducers,
	});

	return {
		thunk,
		slice,
		extraReducers,
		pathKey,
		getCacheKey,
	};
};
