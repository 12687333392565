import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getThunkAndSlice } from "@src/store/getThunkAndSlice";
import { ApiProvider } from "../ApiProvider";
import { RootState } from "@src/store/store";

export interface EventInventoryRecord {
	Listing_ID: number;
	Event_ID: number;
	Section: string;
	Row: string;
	Seat_Number: number;
	Seats: string;
	Quantity: number;
	Item_ID: number;
	Consignment: boolean;
	Company_Name: string;
	OnExchange: boolean;
	OnWeb: boolean;
	SH_eDelivery: boolean;
	Item_Cost: number;
	Item_Price: number;
	Face: number;
	Internal_Notes: string;
	External_Notes: string;
	Last_Price_Update: string;
	Days_Since_Last_Price_Update: number;
	StubHub_Listing_ID: bigint;
	StubHub_Event_ID: number;
	StubHub_Result: string;
	StubHub_Expiration_Date?: Date | null;
	StubHub_Splits?: number | null;
	StubHubInt_Listing_ID: bigint;
	StubHubInt_Event_ID: bigint;
	StubHubInt_Result: string;
	Is_AutoPriced: boolean;
	Is_No_Comparables: boolean;
	Is_At_Floor: boolean;
	Is_At_Ceiling: boolean;
	Is_Error: boolean;
	Ceiling_Price?: number | null;
	Floor_Price?: number | null;
	Allow_Price_Increase?: boolean | null;
	AutoPricer_User: string;
	Minutes_Per_Cycle?: number | null;
	On_Hold: boolean;
	Consignor_Hold: boolean;
	On_Hold_Comments: string;
	PDF_Attached: boolean;
	Barcode_Attached: boolean;
	Ticket_ID_Attached: boolean;
	Hide_Seats?: boolean | null;
	TicketsNow_Listing_ID: number;
	Vivid_Listing_ID: number;
	Vivid_Result: string;
	Vivid_Splits?: number | null;
	SeatGeek_Result: string;
	SeatGeek_Event_ID?: number | null;
	SeatGeek_Listing_ID?: number | null;
	TicketNetwork_Listing_ID: number;
	TicketNetwork_Result: string;
	TicketNetwork_Exchange_ID: number;
	TicketsNow_Result: string;
	InHand?: boolean | null;
	InHandDate?: Date | null;
	EDelivery?: boolean | null;
	Item_Status_ID: number;
	Export_Destination_ID_CSV: string;
	Tags: string | null;
	MasterRuleId?: number | null;
	MasterOrder?: number | null;
	MasterRuleName: string;
	Invoice_ID?: number | null;
	Invoice_Amount?: number | null;
	Invoice_Date?: Date | null;
	Exchange_Name: string;
	Payment_Amount?: number | null;
	Commission_Amount?: number | null;
	Buyers_Overs?: number | null;
	PO_ID?: number | null;
	PO_Date?: Date | null;
	Confirmation_No: string;
	Purchaser_Name?: string;
	Account_Name?: string;
	Vendor?: string;
	Credit_Card?: string;
	Section_Locked?: boolean | null;
	Row_Locked?: boolean | null;
	Seat_Locked?: boolean | null;
	Cost_Locked?: boolean | null;
	Is_Scheduled_Pricing?: boolean | null;
	Shared_To: string;
	Completed_Date?: Date | null;
	Active_Tiers?: number | null;
	Is_Odd_Even: boolean;
	AXS_Listing_ID: number;
	FlashSaleEnabled?: boolean | null;
	FlashSaleDiscount?: number | null;
	IsListingSharedWithGT: boolean;
	Automation_Notes: string;
	Tickets_Available_Date?: Date | null;
}
const initialState = new Array<EventInventoryRecord>();
export const {
	thunk: queryEventInventoryAll,
	slice: hiddenQueryEventInventoryAllSlice,
	extraReducers: hiddenExtraReducers,
} = getThunkAndSlice<
	{
		CompanyName?: string | null;
		EventId: number;
		ForceRefresh?: boolean | null;
	},
	{
		EventInventory: typeof initialState;
	},
	typeof initialState
>({
	initialState,
	path: "/api/Pricing/QueryEventInventoryAll",
	cacheId: "EventId",
	responsePath: "EventInventory",
	method: "post",
});

export const queryEventInventoryAllSlice = createSlice({
	name: hiddenQueryEventInventoryAllSlice.name,
	initialState: hiddenQueryEventInventoryAllSlice.getInitialState(),
	reducers: {
		updateEventInventoryItemsTags: (state, action: PayloadAction<{ itemIds: Array<number>; tags: Array<string> }>) => {
			const { itemIds, tags } = action.payload;
			state.data = state.data.map((x) => {
				if (itemIds.includes(x.Item_ID)) {
					const Tags = tags.length > 0 ? tags.join("|") : null;
					return {
						...x,
						Tags,
					};
				}
				return x;
			});
			state.version++;
		},
		removeItemTag: (state, action: PayloadAction<{ itemIds: Array<number>; tag: string; eventId?: number }>) => {
			const { itemIds, tag, eventId } = action.payload;
			const newState = state.data.map((x) => {
				if (itemIds.includes(x.Item_ID)) {
					const newTags = x.Tags?.split("|").filter((i) => i !== tag);
					const Tags = newTags && newTags.length > 0 ? newTags.join("|") : null;
					return {
						...x,
						Tags,
					};
				}
				return x;
			});
			if (eventId) {
				ApiProvider.default.setCache("_api_Pricing_QueryEventInventoryAll", String(eventId), newState);
			}

			state.data = newState;
			state.version++;
		},
		updateInventoryAll: (state, action: PayloadAction<{ data: Array<EventInventoryRecord>; eventId?: number }>) => {
			state.data = action.payload.data;
			state.version++;
			const { data, eventId } = action.payload;
			if (eventId) {
				ApiProvider.default.setCache("_api_Pricing_QueryEventInventoryAll", String(eventId), data);
			}
		},
		reset: (state) => {
			state.data = [];
		},
	},
	extraReducers: hiddenExtraReducers,
});

const _selectEventInventoryData = (state: RootState) => state.pricing.eventInventoryAll.data;
const _selectEventInventoryLoading = (state: RootState) => state.pricing.eventInventoryAll.loading;
const _selectEventInventoryError = (state: RootState) =>
	state.pricing.eventInventoryAll.error ?? "No Event Inventory Data Available";

export const selectEventInventoryData = createSelector(
	[_selectEventInventoryData, _selectEventInventoryLoading, _selectEventInventoryError],
	(eventInventoryData, eventInventoryLoading, eventInventoryError) => ({
		eventInventoryData,
		eventInventoryLoading,
		eventInventoryError,
	}),
);
