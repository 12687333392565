// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Highlight-module_root___Pr2ai {
  outline: 1px solid transparent;
}
.Highlight-module_root___Pr2ai[data-is-highlighted=true] {
  animation: Highlight-module_highlightPulse___w7G2e 5s linear forwards;
}

@keyframes Highlight-module_highlightPulse___w7G2e {
  0%, 20%, 40%, 60%, 80% {
    outline: 1px solid #ec6728;
  }
  10%, 30%, 50%, 70%, 90% {
    outline: 1px solid transparent;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/highlight/Highlight.module.scss"],"names":[],"mappings":"AAAA;EACC,8BAAA;AACD;AACC;EACC,qEAAA;AACF;;AAGA;EACC;IAKC,0BAAA;EAJA;EAMD;IAKC,8BAAA;EARA;AACF","sourcesContent":[".root {\r\n\toutline: 1px solid transparent;\r\n\r\n\t&[data-is-highlighted=\"true\"] {\r\n\t\tanimation: highlightPulse 5s linear forwards;\r\n\t}\r\n}\r\n\r\n@keyframes highlightPulse {\r\n\t0%,\r\n\t20%,\r\n\t40%,\r\n\t60%,\r\n\t80% {\r\n\t\toutline: 1px solid #ec6728;\r\n\t}\r\n\t10%,\r\n\t30%,\r\n\t50%,\r\n\t70%,\r\n\t90% {\r\n\t\toutline: 1px solid transparent;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Highlight-module_root___Pr2ai`,
	"highlightPulse": `Highlight-module_highlightPulse___w7G2e`
};
export default ___CSS_LOADER_EXPORT___;
