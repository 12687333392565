import { fromType } from "@src/store/helpers";
import { getThunkAndSlice } from "@src/store/getThunkAndSlice";
import { snowFlakeBaseUrl } from "@src/store/ApiProvider";

export type TActiveList = {
	date: string;
	averagePrice: number;
	getInPrice: number;
	listingsCount: number;
};

export type ExpandedTActiveList = TActiveList & { section: string };

export type TActiveInitialState = {
	eventData: Array<TActiveList>;
	sectionData: Array<ExpandedTActiveList>;
};

const initialState = fromType<TActiveInitialState>({
	eventData: [],
	sectionData: [],
});

export const { thunk: getActiveChartsPlus, slice: getActiveChartsPlusSlice } = getThunkAndSlice<
	{
		eventId: number;
	},
	typeof initialState
>({
	initialState,
	externalPath: ({ eventId }) => `${snowFlakeBaseUrl}/active-listings?eventId=${eventId}`,
	path: ({ eventId }) => `/api/activeCharts/${eventId}/data`,
	cacheId: "eventId",
});
