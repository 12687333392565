import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

interface Ticket {
	FileName: string;
	ItemId: number;
}

interface AttachedItem {
	ItemId: number;
	FileName: string;
	SeatNumber: number;
}
interface Verification {
	isHeadlinerMatched: boolean;
	isEventDateMatched: boolean;
	isEventTimeMatched: boolean;
	isSectionMatched: boolean;
	isRowMatched: boolean;
	isSeatNumberMatched: boolean;
	errorMessage: string;
}
const initialState: {
	failedTickets: Array<AttachedItem>;
	validationSummary: Array<Verification>;
} = {
	failedTickets: [],
	validationSummary: [],
};

export const { thunk: attachPdfs, slice: attachPdfsSlice } = getThunkAndSlice<
	{
		attachedTickets: Array<Ticket>;
		listingId: number | undefined | null;
		quantity: number | undefined | null;
		eventName: string | undefined | null;
		eventDate: string | undefined | null;
		eventTime: string | undefined | null;
		eventHeadliner: string | undefined | null;
		catId: number | undefined | null;
		section: string | undefined | null;
		row: string | undefined | null;
	},
	typeof initialState
>({
	initialState,
	path: "/api/System/AttachPdfs",
	method: "post",
});
