import React from "react";
import styles from "./InventoryContextMenu.module.scss";
import { Button, ButtonVariant } from "@src/components/button/Button";
import { useAppDispatch } from "@src/store/hooks";
import { eventActions } from "@src/store";
import {
	HighlightedSectionEnum,
	InventoryActionEnum,
	ManageInventoryTabEnum,
	SelectedManageInventoryTabEnum,
} from "@src/store/pricing/selectionSlice";
import { MixpanelCategory, mixpanelTrack } from "@src/components/utils/mixPanelUtils";
import { EventInventoryRecord } from "@src/store/pricing/queryEventInventoryAllSlice";
import { externalAutoPricerRowClick } from "@src/pages/home/children/pricing/components/inventory/Inventory";

type TInventoryContextMenu = {
	selectedRow: EventInventoryRecord | null;
	closeContextMenu: () => void;
	onHelpOpen: () => void;
};

const InventoryContextMenu: React.FC<TInventoryContextMenu> = ({ selectedRow, closeContextMenu, onHelpOpen }) => {
	const dispatch = useAppDispatch();
	const selectedId = selectedRow?.Item_ID;

	const handleSetInventoryId = () => {
		if (!selectedId) return;
		dispatch(
			eventActions.setInventory({
				inventoryItemIds: [selectedId],
				inventoryAction: InventoryActionEnum.Edit,
			}),
		);
	};

	const handleEditButtonClick = ({ tab, section }: { tab: SelectedManageInventoryTabEnum; section: HighlightedSectionEnum }) => {
		if (!selectedId) return;
		handleSetInventoryId();
		dispatch(eventActions.setManageInventoryTab(ManageInventoryTabEnum.ManageListing));
		dispatch(eventActions.setSelectedInventoryTab(SelectedManageInventoryTabEnum[tab]));
		dispatch(eventActions.setHighlightedSection(section));

		mixpanelTrack(MixpanelCategory.EDIT_INVENTORY_CLICKED, {
			data: selectedId,
		});

		closeContextMenu();
	};

	const handleOpenAutopricer = () => {
		if (!selectedId) return;
		externalAutoPricerRowClick(selectedRow);
		handleSetInventoryId();
		dispatch(eventActions.setManageInventoryTab(ManageInventoryTabEnum.Autopricer));
		closeContextMenu();
	};

	const handleHelpAndSupportOpen = () => {
		onHelpOpen();
		closeContextMenu();
	};

	return (
		<div className={styles.inventoryContextRoot}>
			{/* Basic */}
			<Button onClick={handleOpenAutopricer} variant={ButtonVariant.Quinary} controlSize="xsm">
				Autopricer
			</Button>
			<Button
				onClick={() =>
					handleEditButtonClick({ tab: SelectedManageInventoryTabEnum.Basic, section: HighlightedSectionEnum.ListingInfo })
				}
				variant={ButtonVariant.Quinary}
				controlSize="xsm">
				Listing Info
			</Button>
			<Button
				onClick={() =>
					handleEditButtonClick({ tab: SelectedManageInventoryTabEnum.Basic, section: HighlightedSectionEnum.ShareUnshare })
				}
				variant={ButtonVariant.Quinary}
				controlSize="xsm">
				Share/Unshare
			</Button>
			<Button
				onClick={() =>
					handleEditButtonClick({ tab: SelectedManageInventoryTabEnum.Basic, section: HighlightedSectionEnum.Tickets })
				}
				variant={ButtonVariant.Quinary}
				controlSize="xsm">
				Tickets
			</Button>
			<Button
				onClick={() =>
					handleEditButtonClick({ tab: SelectedManageInventoryTabEnum.Basic, section: HighlightedSectionEnum.InternalNotes })
				}
				variant={ButtonVariant.Quinary}
				controlSize="xsm">
				Internal Notes
			</Button>
			<Button
				onClick={() =>
					handleEditButtonClick({ tab: SelectedManageInventoryTabEnum.Basic, section: HighlightedSectionEnum.InternalNotes })
				}
				variant={ButtonVariant.Quinary}
				controlSize="xsm">
				External Notes
			</Button>
			<Button
				onClick={() =>
					handleEditButtonClick({ tab: SelectedManageInventoryTabEnum.Basic, section: HighlightedSectionEnum.AttachPDF })
				}
				variant={ButtonVariant.Quinary}
				controlSize="xsm">
				Attach PDF's
			</Button>
			{/* Advanced */}
			<Button
				onClick={() =>
					handleEditButtonClick({
						tab: SelectedManageInventoryTabEnum.Advanced,
						section: HighlightedSectionEnum.SchedulePrice,
					})
				}
				variant={ButtonVariant.Quinary}
				controlSize="xsm">
				Schedule Price
			</Button>
			<Button
				onClick={() =>
					handleEditButtonClick({ tab: SelectedManageInventoryTabEnum.Advanced, section: HighlightedSectionEnum.FlashSale })
				}
				variant={ButtonVariant.Quinary}
				controlSize="xsm">
				Flash Sale
			</Button>
			<Button
				onClick={() =>
					handleEditButtonClick({
						tab: SelectedManageInventoryTabEnum.Advanced,
						section: HighlightedSectionEnum.PurchaseInformation,
					})
				}
				variant={ButtonVariant.Quinary}
				controlSize="xsm">
				Purchase Information
			</Button>
			<Button
				onClick={() =>
					handleEditButtonClick({ tab: SelectedManageInventoryTabEnum.Advanced, section: HighlightedSectionEnum.AddTag })
				}
				variant={ButtonVariant.Quinary}
				controlSize="xsm">
				Add Tag
			</Button>
			<Button
				onClick={() =>
					handleEditButtonClick({
						tab: SelectedManageInventoryTabEnum.Advanced,
						section: HighlightedSectionEnum.SplitListing,
					})
				}
				variant={ButtonVariant.Quinary}
				controlSize="xsm">
				Split Listing
			</Button>
			<Button onClick={handleHelpAndSupportOpen} variant={ButtonVariant.Quinary} controlSize="xsm">
				Help & Support
			</Button>
		</div>
	);
};

export default InventoryContextMenu;
