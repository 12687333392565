import { fromType } from "@src/store/helpers";
import { getThunkAndSlice } from "@src/store/getThunkAndSlice";
import { snowFlakeBaseUrl } from "@src/store/ApiProvider";

export type TSoldList = {
	averagePrice: number;
	date: string;
	ticketCount: number;
};

export type ExpandedSoldList = TSoldList & { section: string };

export type TSoldInitialState = {
	eventData: Array<TSoldList>;
	sectionData: Array<ExpandedSoldList>;
};

const initialState = fromType<TSoldInitialState>({
	eventData: [],
	sectionData: [],
});

export const { thunk: getSoldChartsPlus, slice: getSoldChartsPlusSlice } = getThunkAndSlice<
	{
		eventId: number;
	},
	typeof initialState
>({
	initialState,
	externalPath: ({ eventId }) => `${snowFlakeBaseUrl}/sales?eventId=${eventId}`,
	path: ({ eventId }) => `/api/salesChart/${eventId}/data`,
	cacheId: "eventId",
});
