import { AddOutlineIcon, TagIcon, XIcon } from "@src/assets/general-icons";
import { IModalHandle, Modal } from "@src/components/modal/Modal";
import { eventActions, systemActions } from "@src/store";
import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import { toast } from "sonner";
import { Button, ButtonVariant } from "../button/Button";
import { EditTag } from "../editTag/EditTag";
import { MixpanelCategory, mixpanelTrack } from "../utils/mixPanelUtils";
import styles from "./TagModal.module.scss";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { AppDispatch } from "@src/store/store";

export interface ITag {
	type: "Event" | "Inventory";
	itemIds: Array<number>;
	customIcon?: JSX.Element;
	initialTags: Array<string>;
	disableTooltip?: boolean;
	listingId?: number;
	onSave: (itemIds: Array<number>, tags: Array<string>, dispatch: AppDispatch) => void;
}

export const TagModal: React.FC<ITag> = ({
	type,
	itemIds,
	customIcon,
	listingId,
	disableTooltip = false,
	initialTags = [],
	onSave,
}) => {
	const [loading, setLoading] = useState(false);
	const modalRef = useRef<IModalHandle>(null);
	const [tags, setTags] = useState(initialTags);

	const openModal = () => modalRef.current?.openModal();
	const closeModal = () => modalRef.current?.closeModal();
	const id = `tagUniqueId${itemIds.join()}`;

	const externalOpenModalId = useAppSelector((state) => state.pricing.selection.openTagModalWithId);

	const handleExternalModalClose = () => {
		if (externalOpenModalId) {
			dispatch(eventActions.setTagModalId(null));
		}
	};

	useEffect(() => {
		if (!modalRef.current || externalOpenModalId !== listingId) return;
		openModal();
	}, [externalOpenModalId, listingId]);

	const handleCloseAndExternalClose = () => {
		handleExternalModalClose();
		closeModal();
	};

	const dispatch = useAppDispatch();

	const handleSave = async () => {
		try {
			setLoading(true);
			type === "Inventory"
				? await dispatch(
						systemActions.addItemTags({
							ItemIds: itemIds,
							TagDescriptions: tags,
						}),
					).unwrap()
				: await dispatch(
						systemActions.addEventTags({
							EventIds: itemIds,
							TagDescriptions: tags,
						}),
					).unwrap();
		} catch (error) {
			toast.warning("An error occured");
		} finally {
			setLoading(false);
			onSave(itemIds, tags, dispatch);
			handleCloseAndExternalClose();
		}
	};

	const handleTagIconClick = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		openModal();

		mixpanelTrack(MixpanelCategory.TAG_BUTTON_CLICKED, {
			data: `${type} ID: ${itemIds.join(",")}`,
		});
	};

	const hasTags = initialTags.length > 0;
	let icon = hasTags ? <TagIcon /> : <AddOutlineIcon />;
	if (customIcon) icon = customIcon;

	useEffect(() => {
		setTags(initialTags);
	}, [initialTags]);

	return (
		<>
			{hasTags && !disableTooltip && <Tooltip anchorSelect={`#${id}`}>{initialTags.join(", ")}</Tooltip>}
			<Modal
				customButton={
					<div style={{ height: "100%" }} aria-hidden="true" onClick={handleTagIconClick} id={id}>
						{icon}
					</div>
				}
				ref={modalRef}>
				<div className={styles.modalContainer}>
					<div className={styles.headline}>
						<div>Tag {type === "Event" ? "Event" : "Listing"}</div>
						<div aria-hidden="true" style={{ cursor: "pointer" }} onClick={handleCloseAndExternalClose}>
							<XIcon />
						</div>
					</div>
					<div className={styles.content}>
						<div className={styles.headlineText}>Edit Tag</div>
						<EditTag
							type={type}
							listingId={listingId}
							initialTags={tags}
							onChange={(tags) => {
								setTags(tags);
							}}
						/>
						<div className={styles.buttons}>
							<Button
								onClick={handleCloseAndExternalClose}
								style={{ minWidth: "100px", fontSize: "14px" }}
								variant={ButtonVariant.Tertiary}>
								Cancel
							</Button>
							<Button
								onClick={handleSave}
								disabled={loading}
								isLoading={loading}
								style={{ minWidth: "100px", fontSize: "14px" }}
								variant={ButtonVariant.Primary}>
								Save
							</Button>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};
