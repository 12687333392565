import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EyeHide, EyeShow } from "@src/assets/general-icons";
import { EditInventory } from "@src/components/editInventory/EditInventory";
import helperStyles from "@src/components/helpers.module.scss";
import { TagModal } from "@src/components/tagModal/TagModal";
import { DateFormatsEnum, daysDifference, formatDate } from "@src/components/utils/dateUtils";
import { formatCurrency } from "@src/components/utils/numberUtils";
import { IColumn as IVColumn } from "@src/components/vGrid/VGrid";
import { ApStatus } from "@src/pages/home/children/pricing/components/inventory/components/ApStatus";
import { Marketplaces } from "@src/pages/home/children/pricing/components/inventory/components/Marketplaces";
import { EventInventoryRecord } from "@src/store/pricing/queryEventInventoryAllSlice";
import _ from "lodash";
import React from "react";
import { pricingActions, settingsActions } from "..";
import { EventRecord } from "./queryEventsSlice";
import styles from "./settingsSlice.module.scss";
import Properties from "@src/pages/home/children/pricing/components/inventory/components/Properties";

interface ICustomizable {
	groupName: string;
	isChecked?: boolean;
}

export type ColumnListType = Array<[string, boolean]>; // [column, isChecked]
type EventColumnType = IVColumn<EventRecord> & ICustomizable;
export const eventsColumns: Array<EventColumnType> = [
	{
		header: "Days",
		id: "Days",
		accessor: "Event_Date",
		formatValue: (x) => daysDifference(x),
		hasTooltip: true,
		width: 80,
		isChecked: true,
		groupName: "Main Info",
		sortable: true,
	},
	{
		header: "Category",
		id: "Category",
		accessor: "Cat_Type",
		hasTooltip: true,
		width: 250,
		groupName: "Main Info",
		sortable: true,
	},
	{
		header: "Headliner",
		id: "Headliner",
		accessor: "Event_Headliner",
		hasTooltip: true,
		width: 250,
		isChecked: true,
		groupName: "Main Info",
		sortable: true,
	},
	{
		header: "Event",
		id: "Event",
		accessor: "Event_Name",
		hasTooltip: true,
		width: 330,
		isChecked: true,
		groupName: "Main Info",
		sortable: true,
	},
	{
		header: "Event ID",
		id: "EventID",
		accessor: "Event_ID",
		hasTooltip: true,
		width: 100,
		groupName: "Main Info",
		sortable: true,
	},
	{
		header: "Day",
		id: "Day",
		accessor: "Event_Date",
		width: 80,
		formatValue: (x) => formatDate(x, DateFormatsEnum.dddd),
		isChecked: true,
		groupName: "Main Info",
		sortable: true,
	},
	{
		header: "Date",
		id: "Date",
		accessor: "Event_Date",
		width: 85,
		formatValue: (x) => formatDate(x, DateFormatsEnum["MM/DD/YYYY"]),
		isChecked: true,
		groupName: "Main Info",
	},
	{
		header: "Time",
		id: "Time",
		accessor: "Event_Time",
		width: 80,
		isChecked: true,
		groupName: "Main Info",
	},
	{
		header: "Venue",
		id: "Venue",
		accessor: "Venue_Name",
		hasTooltip: true,
		width: 180,
		isChecked: true,
		groupName: "Main Info",
	},
	{
		header: "City",
		id: "City",
		accessor: "Venue_City",
		hasTooltip: true,
		width: 180,
		groupName: "Main Info",
		sortable: true,
	},
	{
		header: "State",
		id: "State",
		accessor: "Venue_State",
		hasTooltip: true,
		width: 180,
		groupName: "Main Info",
		sortable: true,
	},
	{
		header: "Open cost",
		id: "OpenCost",
		accessor: "Open_Cost",
		width: 90,
		formatValue: (x) => formatCurrency(x),
		isChecked: true,
		groupName: "Main Info",
		sortable: true,
	},
	{
		header: "Cost",
		id: "Cost",
		accessor: "Total_Cost",
		width: 90,
		formatValue: (x) => formatCurrency(x),
		groupName: "Main Info",
		sortable: true,
	},
	{
		header: "Open",
		id: "Open",
		accessor: "Total_Open",
		width: 80,
		isChecked: true,
		groupName: "Main Info",
		sortable: true,
	},

	{
		header: "Reserved",
		id: "Reserved",
		accessor: "Total_Reserved",
		width: 60,
		groupName: "Main Info",
		sortable: true,
	},
	{
		header: "Sold",
		id: "Sold",
		accessor: "Total_Sold",
		width: 60,
		isChecked: true,
		groupName: "Main Info",
		sortable: true,
	},
	{
		header: "Unpriced",
		id: "Unpriced",
		accessor: "Total_Unpriced",
		width: 90,
		isChecked: true,
		groupName: "Main Info",
		sortable: true,
	},
	{
		header: "Unshared",
		id: "Unshared",
		accessor: "Total_Unshared",
		width: 90,
		isChecked: true,
		groupName: "Main Info",
		sortable: true,
	},
	{
		header: "Tickets",
		accessor: "Total_Open",
		id: "Tickets",
		valueGetter: (params) => params.rowData.Total_Open + params.rowData.Total_Sold,
		formatValue: (x, { rowData }) => x + rowData.Total_Sold,
		width: 60,
		groupName: "Main Info",
		sortable: true,
	},
	{
		header: "Tag",
		id: "Tag",
		accessor: "Tags",
		formatValue: (x, { rowData }) => (
			<TagModal
				type="Event"
				listingId={rowData.Event_ID}
				itemIds={[rowData.Event_ID]}
				initialTags={x?.split("|") || []}
				onSave={(itemIds, tags, dispatch) => {
					dispatch(pricingActions.updateEventItemsTags({ itemIds, tags }));
				}}
			/>
		),
		width: 50,
		disableTitle: true,
		groupName: "Main Info",
	},
];
export const defaultEventColumns: ColumnListType = eventsColumns.map((x) => [`${x.id}`, !!x.isChecked]);

export const extractMarketplaceData = (data: EventInventoryRecord) => {
	const {
		StubHub_Listing_ID,
		StubHubInt_Listing_ID,
		TicketsNow_Listing_ID,
		Vivid_Listing_ID,
		TicketNetwork_Listing_ID,
		SeatGeek_Listing_ID,
		AXS_Listing_ID,
		Listing_ID,
	} = data;

	return {
		StubHub_Listing_ID,
		StubHubInt_Listing_ID,
		TicketsNow_Listing_ID,
		Vivid_Listing_ID,
		TicketNetwork_Listing_ID,
		SeatGeek_Listing_ID,
		AXS_Listing_ID,
		Listing_ID,
	};
};
type InventoryColumnType = IVColumn<EventInventoryRecord> & ICustomizable;
export const inventoryColumns: Array<InventoryColumnType> = [
	{
		header: "ListingID",
		id: "ListingID",
		accessor: "Listing_ID",
		width: 95,
		isChecked: true,
		groupName: "Main Info",
		sortable: true,
	},
	{
		header: "Company",
		id: "Company",
		accessor: "Company_Name",
		width: 150,
		groupName: "Main Info",
		sortable: true,
	},
	{
		header: "Section",
		id: "Section",
		accessor: "Section",
		getClassName: () => helperStyles.secondaryText,
		width: 115,
		isChecked: true,
		groupName: "Main Info",
		sortable: true,
	},
	{
		header: "Row",
		id: "Row",
		accessor: "Row",
		getClassName: () => helperStyles.secondaryText,
		width: 60,
		isChecked: true,
		groupName: "Main Info",
		sortable: true,
	},
	{
		header: "Seat",
		id: "Seat",
		accessor: "Seats",
		valueGetter: ({ rowData }) => rowData.Seats || rowData.Seat_Number,
		formatValue: (x, { rowData }) => x || rowData.Seat_Number,
		getClassName: () => helperStyles.secondaryText,
		width: 68,
		isChecked: true,
		groupName: "Main Info",
		sortable: true,
	},
	{
		header: "Quantity",
		id: "Quantity",
		accessor: "Quantity",
		valueGetter: ({ rowData }) => rowData.Quantity || 1,
		formatValue: (x) => x || 1,
		getClassName: () => helperStyles.secondaryText,
		width: 55,
		isChecked: true,
		groupName: "Main Info",
		sortable: true,
	},
	{
		header: "Splits",
		id: "Splits",
		accessor: "Vivid_Splits",
		valueGetter: ({ rowData }) => {
			switch (rowData.Vivid_Splits) {
				case 2:
					return "Pairs";
				case 1:
					return "Any";
				case 0:
					return "None";
				case -1:
				default:
					return "Default";
			}
		},
		formatValue: (x) => {
			switch (x) {
				case 2:
					return "Pairs";
				case 1:
					return "Any";
				case 0:
					return "None";
				case -1:
				default:
					return "Default";
			}
		},
		getClassName: () => helperStyles.secondaryText,
		width: 68,
		isChecked: true,
		groupName: "Main Info",
		sortable: true,
	},
	{
		header: "Cost",
		id: "Cost",
		accessor: "Item_Cost",
		formatValue: (x, { rowData }) => formatCurrency(x / rowData.Quantity),
		width: 90,
		isChecked: true,
		groupName: "Main Info",
		sortable: true,
		formatTooltip: (x) => {
			return formatCurrency(x);
		},
		isEditable: (params) => {
			return !!params.rowData.Invoice_ID;
		},
		isCurrency: true,
		editableLabel: "Edit Cost",
	},
	{
		header: "Price",
		id: "Price",
		accessor: "Item_Price",
		formatValue: (x) => <div className={styles.priceContainer}>{formatCurrency(x)}</div>,
		width: 90,
		isChecked: true,
		groupName: "Main Info",
		sortable: true,
		formatTooltip: (x) => {
			return formatCurrency(x);
		},
		isEditable: (params) => {
			return !params.rowData.Invoice_ID;
		},
		isCurrency: true,
		editableLabel: "Edit Price",
	},
	{
		header: "GTFS",
		id: "GTFS",
		accessor: "FlashSaleDiscount",
		formatValue: (x) => `${x ?? 0}%`,
		width: 65,
		isChecked: true,
		groupName: "Additional Info",
		sortable: true,
	},
	{
		header: "In-Hand",
		id: "InHand",
		accessor: "InHandDate",
		formatValue: (x) => formatDate(x),
		width: 90,
		isChecked: true,
		groupName: "Additional Info",
		sortable: true,
	},
	{
		header: "Auto Pricer",
		id: "APStatus",
		accessor: "Is_AutoPriced",
		formatValue: (_x, { rowData }) => <ApStatus row={rowData} />,
		width: 106,
		isChecked: true,
		groupName: "Additional Info",
		sortable: true,
	},
	// {
	// 	header: "Price Ceiling",
	// 	id: "PriceCeiling",
	// 	accessor: "Ceiling_Price",
	// 	formatValue: (x) => <PricePrefix data={x} />,
	// 	width: 110,
	// 	isChecked: true,
	// 	groupName: "Additional Info",
	// 	sortable: true,
	// 	isEditable: true,
	// 	editableLabel: "Edit Ceiling",
	// },
	// {
	// 	header: "Price Floor",
	// 	id: "PriceFloor",
	// 	accessor: "Floor_Price",
	// 	formatValue: (x) => <PricePrefix data={x} />,
	// 	width: 100,
	// 	isChecked: true,
	// 	groupName: "Additional Info",
	// 	sortable: true,
	// 	isEditable: true,
	// 	editableLabel: "Edit Floor",
	// },
	{
		header: "Marketplaces",
		id: "Marketplaces",
		accessor: "Invoice_ID",
		formatValue: (x, { rowData }) => (x ? "" : <Marketplaces data={extractMarketplaceData(rowData)} />),
		formatTooltip: (_x) => "Marketplaces",
		width: 190,
		isChecked: true,
		groupName: "Additional Info",
		sortable: true,
		isEditable: false,
		editableLabel: "Edit Sharing",
		onEditClick: (rowData) => {
			console.info("Edit sharing", rowData);
		},
	},
	{
		header: "Last Updated",
		id: "LastUpdated",
		accessor: "Last_Price_Update",
		getClassName: () => helperStyles.secondaryText,
		width: 130,
		hasTooltip: true,
		isChecked: true,
		groupName: "Additional Info",
		sortable: true,
	},
	{
		header: "Properties",
		id: "Properties",
		accessor: "Is_Error",
		formatTooltip: () => {
			return "";
		},
		formatValue: (_x, { rowData }) => <Properties rowData={rowData} />,
		getClassName: () => styles.propertiesContainer,
		width: 90,
		isChecked: true,
		groupName: "Additional Info",
	},
	{
		header: "Hide Seats",
		id: "HideSeats",
		accessor: "Hide_Seats",
		formatValue: (x) => (x ? <EyeHide size="12" /> : <EyeShow size="12" />),
		formatTooltip: (x) => (x ? "Seats are hidden" : "Seats are visible"),
		getClassName: () => styles.propertiesContainer,
		width: 90,
		groupName: "Additional Info",
		sortable: true,
	},
	{
		header: "Internal Notes",
		id: "InternalNotes",
		accessor: "Internal_Notes",
		hasTooltip: true,
		getClassName: () => helperStyles.secondaryText,
		width: 200,
		isChecked: true,
		groupName: "Additional Info",
		sortable: true,
	},
	{
		header: "External Notes",
		id: "ExternalNotes",
		accessor: "External_Notes",
		hasTooltip: true,
		getClassName: () => helperStyles.secondaryText,
		width: 200,
		isChecked: true,
		groupName: "Additional Info",
		sortable: true,
	},
	{
		header: "Tag",
		id: "Tag",
		accessor: "Tags",
		formatValue: (x, { rowData }) => (
			<TagModal
				type="Inventory"
				itemIds={[rowData.Item_ID]}
				listingId={rowData.Listing_ID}
				initialTags={x?.split("|") || []}
				onSave={(itemIds, tags, dispatch) => {
					dispatch(pricingActions.updateEventInventoryItemsTags({ itemIds, tags }));
				}}
			/>
		),
		formatTooltip: (_x) => "Tags",
		width: 50,
		isChecked: true,
		disableTitle: true,
		groupName: "Additional Info",
	},
	{
		header: "TA Date",
		id: "TADate",
		accessor: "Tickets_Available_Date",
		formatValue: (x) => formatDate(x),
		width: 110,
		groupName: "Additional Info",
		sortable: true,
	},
	{
		header: "Automation Notes",
		id: "AutomationNotes",
		accessor: "Automation_Notes",
		width: 110,
		groupName: "Additional Info",
		sortable: true,
	},
	{
		header: "Order Number",
		id: "OrderNumber",
		accessor: "Internal_Notes",
		valueGetter: ({ rowData }) => rowData.Internal_Notes?.split("/")[1]?.trim(),
		formatValue: (x) => x?.split("/")[1]?.trim(),
		width: 200,
		groupName: "Operational Info",
		sortable: true,
	},
	{
		header: "Purchaser",
		id: "Purchaser",
		accessor: "Purchaser_Name",
		width: 110,
		groupName: "Operational Info",
		sortable: true,
	},
	{
		header: "Account",
		id: "Account",
		accessor: "Account_Name",
		width: 110,
		groupName: "Operational Info",
		sortable: true,
	},
	{
		header: "Vendor",
		id: "Vendor",
		accessor: "Vendor",
		width: 110,
		groupName: "Operational Info",
		sortable: true,
	},
	{
		header: "Credit card",
		id: "CreditCard",
		accessor: "Credit_Card",
		width: 110,
		groupName: "Operational Info",
		sortable: true,
	},
	{
		header: "PO ID",
		id: "POID",
		accessor: "PO_ID",
		width: 110,
		groupName: "Operational Info",
		sortable: true,
	},
	{
		header: "PO Date",
		id: "PODate",
		accessor: "PO_Date",
		width: 110,
		formatValue: (x) => formatDate(x),
		groupName: "Operational Info",
		sortable: true,
	},
	{
		header: "Invoice Amount",
		id: "InvoiceAmount",
		accessor: "Invoice_Amount",
		width: 110,
		groupName: "Operational Info",
		sortable: true,
	},
	{
		header: " ",
		id: "Options",
		accessor: "Item_ID",
		formatValue: (_x) => <EditInventory itemId={_x} />,
		width: 55,
		isSticky: true,
		isChecked: true,
		groupName: "Operational Info",
		formatTooltip: (_x) => "Edit",
	},
];
const checkForDuplicateIDs = (columns: Array<{ id: string }>) => {
	const ids = columns.map((column) => column.id);
	const duplicateIDs = _.filter(ids, (value, index, iteratee) => _.includes(iteratee, value, index + 1));
	if (duplicateIDs.length > 0) throw new Error(`Duplicate IDs found: ${duplicateIDs.join(", ")}`);
};
checkForDuplicateIDs(eventsColumns);
checkForDuplicateIDs(inventoryColumns);

export const defaultInventoryColumns: ColumnListType = inventoryColumns.map((x) => [`${x.id}`, !!x.isChecked]);

export interface IColumn {
	label: string;
	id: string;
	isSticky: boolean;
	checked: boolean;
}

export interface IGroup {
	header: string;
	columns: Array<IColumn>;
}

export interface IColumnGroups {
	Event: Array<IGroup>;
	Inventory: Array<IGroup>;
}

export const defaultGroups: IColumnGroups = {
	Event: _.map(
		_.groupBy(eventsColumns, (x) => x.groupName),
		(value, key) => {
			return {
				header: key,
				columns: value.map((x) => {
					return {
						label: `${x.header}`,
						id: `${x.id}`,
						isSticky: !!x.isSticky,
						checked: !!x.isChecked,
					};
				}),
			};
		},
	),
	Inventory: _.map(
		_.groupBy(inventoryColumns, (x) => x.groupName),
		(value, key) => {
			return {
				header: key,
				columns: value.map((x) => {
					return {
						label: `${x.header}`,
						id: `${x.id}`,
						isSticky: !!x.isSticky,
						checked: !!x.isChecked,
					};
				}),
			};
		},
	),
};

interface ISelectedEvent {
	columns: {
		Event: Array<EventColumnType>;
		Inventory: Array<InventoryColumnType>;
	};
}

const initialState: ISelectedEvent = {
	columns: {
		Event: [],
		Inventory: [],
	},
};

export const setEventColumns = createAsyncThunk<unknown, ColumnListType>(
	"settings/setEventColumns",
	async (columns, { dispatch }) => {
		dispatch(settingsSlice.actions.setEventColumns(columns));
		dispatch(settingsActions.setPricingEventsColumns(columns));
	},
);

export const setInventoryColumns = createAsyncThunk<unknown, ColumnListType>(
	"settings/setInventoryColumns",
	async (columns, { dispatch }) => {
		dispatch(settingsSlice.actions.setInventoryColumns(columns));
		dispatch(settingsActions.setPricingInventoryColumns(columns));
	},
);

export const settingsSlice = createSlice({
	name: "pricing.settingsSlice",
	initialState,
	reducers: {
		setEventColumns: (state, action: PayloadAction<ColumnListType>) => {
			const columns = new Array<EventColumnType>();

			action.payload.forEach((x) => {
				if (!x[1] || x[0] === "Tag") return;
				const column = eventsColumns.find((c) => c.id === x[0]);
				if (column) columns.push(column);
			});

			const tagColum = eventsColumns.find((c) => c.id === "Tag");
			if (tagColum) columns.push(tagColum);

			return {
				...state,
				columns: {
					...state.columns,
					Event: columns,
				},
			};
		},
		setInventoryColumns: (state, action: PayloadAction<ColumnListType>) => {
			const columns = new Array<InventoryColumnType>();
			action.payload.forEach((x) => {
				if (!x[1]) return;
				const column = inventoryColumns.find((c) => c.id === x[0]);
				if (column) columns.push(column);
			});

			return {
				...state,
				columns: {
					...state.columns,
					Inventory: columns,
				},
			};
		},
	},
});
