import { paths } from "@src/routes/paths";
import React from "react";
import { useLocation } from "react-router-dom";
import { PricingHeader } from "./components/pricingHeader/PricingHeader";
import { SalesHeader } from "./components/salesHeader/SalesHeader";
import styles from "./Header.module.scss";

type THeaderContent = Record<string, JSX.Element>;

export const Header: React.FC = () => {
	const { pathname } = useLocation();
	const headerLabel = pathname === "/" ? "Inventory" : pathname.slice(1);
	const headerEnum = (pathname.slice(1) || "inventory") as keyof typeof paths;

	const headerVariants: THeaderContent = {
		inventory: <PricingHeader />,
		sales: <SalesHeader />,
	};

	const headerContent = headerVariants[headerEnum];

	return (
		<header className={styles.main}>
			<div className={styles.container}>
				<div className={styles.left}>{headerLabel}</div>
				{headerContent}
			</div>
		</header>
	);
};
