import { createSlice } from "@reduxjs/toolkit";
import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

export interface DepositType {
	Id: number;
	Description: string;
}

export interface Deposit {
	Id: number;
	Type: DepositType;
	Customer: string | null;
	Amount: number;
	RemainingAmount: number;
	Notes: string | null;
	CheckNumber: string;
	CreatedDate: string;
	CreatedUser: string | null;
}

export interface InvoiceItem {
	InvoiceId: number;
	Id: number;
	Deposit: Deposit;
	Amount: number;
	AppliedDate: string;
}

const initialState = new Array<InvoiceItem>();

interface TPaymentHistoryInitialState {
	invoiceId: number;
}

export const {
	thunk: paymentHistory,
	slice: hiddenPaymentHistorySlice,
	extraReducers: hiddenExtraReducers,
} = getThunkAndSlice<TPaymentHistoryInitialState, typeof initialState>({
	initialState,
	path: ({ invoiceId }) => `/api/Accounting/Payments/Invoice/${invoiceId}?page=1&start=0&limit=99999`,
});

export const paymentHistorySlice = createSlice({
	name: hiddenPaymentHistorySlice.name,
	initialState: hiddenPaymentHistorySlice.getInitialState(),
	reducers: {},
	extraReducers: hiddenExtraReducers,
});
