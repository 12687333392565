export const options = [
	{ label: "Need listing split", value: "Need listing split" },
	{ label: "Listing is not on StubHub", value: "Listing is not on StubHub" },
	{ label: "Listing is not mapping to StubHub seating chart", value: "Listing is not mapping to StubHub seating chart" },
	{ label: "Listing is not on TM+", value: "Listing is not on TM+" },
	{ label: "Listing is not on TicketNetwork", value: "Listing is not on TicketNetwork" },
	{ label: "Listing is not on Seatgeek", value: "Listing is not on Seatgeek" },
	{ label: "Listing is not on Vivid Seats", value: "Listing is not on Vivid Seats" },
	{ label: "Attach QR Codes", value: "Attach QR Codes" },
	{ label: "Add Barcodes", value: "Add Barcodes" },
	{ label: "Listing belongs to a different event", value: "Listing belongs to a different event" },
	{ label: "Change in-hand date", value: "Change in-hand date" },
	{ label: "Change seats numbers", value: "Change seats numbers" },
	{ label: "Change seats numbers", value: "Change seats numbers" },
	{ label: "Combine listings", value: "Combine listings" },
	{ label: "Change ticket location to Hard Con", value: "Change ticket location to Hard Con" },
	{ label: "Other", value: "Other" },
];
