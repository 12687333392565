import { Header } from "@src/components/header/Header";
import { Sidebar } from "@src/components/sidebar/Sidebar";
import { useAuth } from "@src/routes/authProvider";
import { paths } from "@src/routes/paths";
import { pricingActions, settingsActions } from "@src/store";
import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

import styles from "./Home.module.scss";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";

export const Home: React.FC = () => {
	const appDispatch = useAppDispatch();
	const portalSidebarExpanded = useAppSelector((state) => state.settings.portalSidebarExpanded);
	// This can hide sidebar with autologin from old portal
	// const portalSidebarIsHidden = useAppSelector((state) => state.settings.portalSidebarIsHidden);

	const isAdmin = useAppSelector((state) => state.settings.isAdmin);
	const companies = useAppSelector((state) => state.pricing.companies.data);

	useEffect(() => {
		if (!isAdmin || companies.length) return;
		appDispatch(pricingActions.listCompanies({}));
	}, [isAdmin, appDispatch, companies.length]);

	const { isAuthenticated } = useAuth();
	if (!isAuthenticated && location.pathname !== paths.login) {
		return <Navigate to={paths.login} replace />;
	}

	const toggleExpanded = () => {
		appDispatch(settingsActions.setPortalSidebarExpanded(!portalSidebarExpanded));
	};

	return (
		<div
			className={styles.main}
			data-is-expanded={portalSidebarExpanded}
			// data-is-side-bar-hidden={portalSidebarIsHidden}
		>
			<Header />
			<Sidebar isExpanded={portalSidebarExpanded} isHidden={false} toggleExpanded={toggleExpanded} />
			<div className={styles.childRoutesContainer}>
				<main>
					<Outlet />
				</main>
			</div>
		</div>
	);
};
