export const paths = {
	autologin: "/autologin",
	login: "/login",
	signup: "/signup",
	home: "/",
	dashboard: "/dashboard",
	pricing: "/inventory",
	reports: "/reports",
	sales: "/sales",
	purchasing: "/purchasing",
	notification: "/notification",
	settings: "/settings",
} as const;
